import { defineStore } from 'pinia';

import {
	EVENTS,
	identify_tracked_user,
	PINIA_PERSIST_OPTIONS,
	register,
	requestLoginOTP,
	encryptKey,
	track,
	verifyLoginToken,
	getUserDetailsByToken,
	getPlans,
	verifyEncryptedLoginToken
} from '@/helpers';
import { set_tracked_user, set_user_properties, track_amplitude, AMPLITUDE_EVENTS } from '@/helpers/amplitude';
import { router } from '@/router';
import { useAlertStore, useContactsStore, useNumbersStore, useUsersStore, useUtilstStore } from '@/stores';
import { validateMobile } from '@/helpers/utils';
import { getActivePinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import logger from '@/helpers/logger';
import { encryptToken } from '@/helpers/encryptionKeys';

export const useAuthStore = defineStore({
	id: 'auth',
	state: () => ({
		// initialize state from local storage to enable user to stay logged in
		mobile_number: null,
		token: null,
		returnUrl: null,
		loading: false,
		authError: null,
		selectedPlanId: null,
		recommendedId: null,
		couponCode: null,
		encryptionData: null
	}),
	getters: {
		isAuthenticated: (state) => !!state.token
	},
	actions: {
		async getOTP(payload) {
			this.authError = '';
			this.loading = true; //it is set to false on SignInView.vue.
			if (payload.scheme == 'VOICE' || payload.scheme == 'SMS') {
				if (payload.mobile) {
					if (payload.mobile.length < 10) {
						throw new Error('Phone number must be at least 10 digits');
					}
					payload.mobile = validateMobile(payload.mobile);
				}
			} else if (!payload.scheme && payload.mobile) {
				payload.mobile = validateMobile(payload.mobile);
			}
			this.encryptionData = await encryptKey({ login_id: payload.mobile });
			return requestLoginOTP(payload); // we intentionally did not catch error
		},
		setSelectedPlanId(id) {
			this.selectedPlanId = id;
		},
		setToken(token) {
			this.token = token;
		},
		clearSelectedPlanId() {
			this.selectedPlanId = null;
		},
		async login(payload) {
			// this.clearStores();
			this.loading = true;
			this.authError = '';
			// console.log('Got Here');
			try {
				const encryptedToken = encryptToken( this.encryptionData.public_key, payload.token) // encrypt token
				// const { data, headers } = await verifyLoginToken(payload);
				const requestPayload = {  // new encryption payload
					login_id: payload.mobile,
					auth_session_id:  this.encryptionData.auth_session_id,
					otp: encryptedToken
				}
				const data = await verifyEncryptedLoginToken(requestPayload)
				this.token = data.token;
				const currentUser = await getUserDetailsByToken();
				const plans = await getPlans();
				this.mobile_number = currentUser.mobile;
				const userStore = useUsersStore();
				const utilStore = useUtilstStore();
				userStore.currentUser = currentUser;
				utilStore.setSubscriptionPlans(plans);
				this.loading = false;
				set_tracked_user(userStore.currentUser.personal_email);
				set_user_properties({
					email: userStore.currentUser.personal_email,
					phone: userStore.currentUser.mobile,
					first_name: userStore.currentUser.first_name,
					last_name: userStore.currentUser.last_name
				});
				track_amplitude(AMPLITUDE_EVENTS.LOGIN);
				identify_tracked_user(userStore.currentUser.personal_email);
				register(userStore.currentUser);
				track(EVENTS.SIGNED_IN, userStore.currentUser);
				// using email as unique identifier for amplitude
				// redirect to previous url or default to home page
				Sentry.configureScope(function (scope) {
					scope.setUser({ email: userStore.currentUser.email });
				});
				if (window.newrelic) {
					window.newrelic.setCustomAttribute('userId', userStore.currentUser.id);
				}
				this.encryptionData =null
				return this.returnUrl ? router.push(this.returnUrl) : router.push('/home');
			} catch (error) {
				console.log(error);
				let err = JSON.parse(error);
				let errorMsg = '';
				if (err.token || err.code === '4001') {
					errorMsg = 'The OTP you entered is incorrect, Kindly check the number and try again';
				} else {
					errorMsg = 'Something went wrong, please try again';
				}
				track(EVENTS.LOGIN_ISSUE, { error: errorMsg });
				this.loading = false;
				const alertStore = useAlertStore();
				alertStore.error(errorMsg);
				this.authError = errorMsg;
				logger.error(errorMsg, 'Something went wrong, please try again');
				throw errorMsg;
			}
		},
		clearStores() {
			//clear stores
			const userStore = useUsersStore();
			userStore.$reset();
			const authStore = useAuthStore();
			authStore.$reset();
			const numStore = useNumbersStore();
			numStore.$reset();
			const utilsStore = useUtilstStore();
			utilsStore.$reset();
			const contactStore = useContactsStore();
			contactStore.$reset();

			// map through that list and use the **$reset** fn to reset the state
			getActivePinia()._s.forEach((store) => store.$reset());
		},
		async logout() {
			this.token = null;
			this.mobile_number = null;
			this.clearStores();
			Sentry.setUser(null);
			const token = localStorage.getItem('token');
			if (token) {
				localStorage.removeItem('token');
			}
			await router.push('/sign-in');
		}
	},
	persist: PINIA_PERSIST_OPTIONS
});
