import { defineStore } from 'pinia';
import { notify } from '@kyvg/vue3-notification';
import { useNumbersStore } from '@/stores/numbers.store';
import { useUtilstStore } from '@/stores/utils.store';
import { useUsersStore } from '@/stores/users.store';
import { generateKeyPair } from '@/helpers/encryptionKeys';
import { fetchSipInfo, track, EVENTS } from '@/helpers';
import { decryptData } from '@/helpers/encryptionKeys';
import { initSIPPhone } from '@/helpers/sip';
import { validateMobile } from '@/helpers/utils';
import { Howl } from 'howler';
import Ringer from '@/assets/sounds/outgoing.mp3';
import Unavailable from '@/assets/sounds/unavailable.mp3';
import Busy from '@/assets/sounds/busy.mp3';
import logger from '@/helpers/logger';
import { navigator } from 'mixpanel-browser/src/utils.js';
import { airtableInboundCallService, airtableService } from '@/helpers/airtable';
import { ENGAGE_EVENTS, engage_track } from '@/helpers/engage';

export const OUTGOING_RINGER = new Howl({
	src: [Ringer],
	loop: true
});

export const INCOMING_RINGER = new Howl({
	src: [Ringer],
	loop: true
});

export const UNAVAILABLE_PROMPT = new Howl({
	src: [Unavailable],
	loop: false
});

export const BUSY_PROMPT = new Howl({
	src: [Busy],
	loop: false
});

export const useDialerStore = defineStore({
	id: 'dialer-v2',
	state: () => ({
		showCallFeedbackModal: false,
		isDialerOffline: false,
		isDialerShowing: false,
		hasInit: false,
		isLoading: true,
		simpleUserInstances: {},
		sipCredentials: {},
		phoneConnectionStatuses: {},
		isCallOngoing: false,
		callStatus: {
			callTransferStatus: '',
			isCallActive: false,
			isIncomingRinging: false,
			isOutgoingRinging: false,
			isOnHold: false,
			isMute: false,
			numberId: null, // the pressone receiver number pk
			outgoingCallTo: null,
			incomingCallFrom: null,
			duration: 0,
			hasAnswered: false,
			showDuration: false,
			showDurationTimer: null,
			_durationInterval: null,
			_incomingCallRingDurationInterval: null,
			incomingCallRingDuration: 0,
			callStatusCode: null,
			responseMessage: '',
			isUnregistering: false
		},
		connectionChecker: null // checks the registration every 5 seconds and reconnects
	}),
	getters: {
		sipInstanceKey() {
			return Object.keys(this.sipCredentials).map(String); // get all recivers numbers

		},
		phonesConnected() {
			const numberStore = useNumbersStore();
			const total = numberStore.numbers.length;
			let connected = 0;
			if (!Object.keys(this.simpleUserInstances).length) {
				return {
					status: false,
					connected,
					total
				};
			}

			for (const phone of Object.values(this.simpleUserInstances)) {
				if (phone.isConnected()) connected += 1;
			}
			return {
				status: connected === total,
				connected,
				total
			};
		},
		activeCallId() {
			// The sip call id, used to store notes, provided by SIP server
			const activePhone = this.simpleUserInstances[this.callStatus.numberId];
			if (!activePhone || !activePhone.session) {
				return;
			}

			if (this.callStatus.outgoingCallTo) {
				// outgoing call
				return activePhone.session.outgoingRequestMessage?.callId;
			} else {
				return activePhone.session.incomingInviteRequest?.message.callId;
			}
		},
		activeContactInCall() {
			return this.callStatus?.outgoingCallTo ?? this.callStatus?.incomingCallFrom;
		}
	},
	actions: {
		setIsDialerShowing(value) {
			this.isDialerShowing = value;
		},
		setShowCallFeedbackModal(value) {
			this.showCallFeedbackModal = value;
		},
		async getSipInfo(number) {
			const keypair = await generateKeyPair();
			const publickey = await keypair.publicKey;
			const userStore = useUsersStore();
			const payload = {
				public_key: publickey,
				receiver: number.id,
				mobile: userStore?.currentUser?.mobile
			};

			try {
				const data = await fetchSipInfo(payload);

				const decryptedPassword = await decryptData(keypair.privateKey, data.password);
				return {
					...data,
					password: decryptedPassword
				};
			} catch (error) {
				if (error == 403) {
					notify({
						type: 'error',
						title: `Connection failed`,
						text: `This service is not supported in your region`
					});
				}
				return {};
			}
		},
		initComplete() {
			this.isLoading = false;
			this.hasInit = true;
		},
		async connectPhone(number) {
			const existingConnection = this.simpleUserInstances[number.id];
			if (existingConnection && existingConnection.isConnected() && !this.isUnregistering) {
				this.isUnregistering = true;
				existingConnection.unregister().then(() => {
					this.isUnregistering = false;
				});
				existingConnection.disconnect();
			}

			this.phoneConnectionStatuses[number.id] = {
				isConnected: false,
				isConnecting: true
			};
			try {
				this.sipCredentials[number.id] = await this.getSipInfo(number);

				// this.simpleUserInstances[number.id] = await initSIPPhone(
				// 	this.sipCredentials[number.id],
				// 	number.business_number.phone_number,
				// 	number.id
				// );
				// console.log(this.simpleUserInstances[number.id]);
			} catch (e) {
				// console.error(`Error connecting phone ${number.business_number.phone_number}`, e);
				track(`registration failure`, {
					phoneNumber: number.business_number.phone_number,
					numberId: number.id
				});
				// notify({
				// 	type: 'error',
				// 	duration: 3000,
				// 	title: `Error connecting ${number.business_number.phone_number}`,
				// 	text: `We are unable to register phone (${number.business_number.phone_number}).`
				// });
			} finally {
				if (this.phoneConnectionStatuses[number.id]) this.phoneConnectionStatuses[number.id].isConnecting = false;
			}
		},
		async initDialer() {
			const numberStore = useNumbersStore();

			for (const number of numberStore.numbers) {
				this.connectPhone(number); // no need to await
			}

			// this.connectionChecker = setInterval(async () => {
			// 	if (!navigator.onLine) {
			// 		this.isDialerOffline = true;
			// 		for (const [_, phone] of Object.entries(this.simpleUserInstances)) {
			// 			phone.disconnect();
			// 		}
			// 		notify({
			// 			title: 'No internet connection',
			// 			text: 'Please check your internet connection. Phone would try to reconnect in 15 secs.',
			// 			type: 'error',
			// 			duration: 3000
			// 		});
			// 	} else {
			// 		if (this.isDialerOffline) {
			// 			notify({
			// 				title: 'Back online!',
			// 				text: 'Dialer is back online. Reconnecting phones...',
			// 				duration: 5000
			// 			});
			// 			this.isDialerOffline = false;
			// 		}
			// 	}

			// 	this.hasInit = true;

			// 	for (const [numberId, phone] of Object.entries(this.simpleUserInstances)) {
			// 		if (!phone.isConnected()) {
			// 			const number = numberStore.numbers.find((num) => parseInt(numberId) === parseInt(num.id));
			// 			if (number) {
			// 				logger.info(`Retrying to connect ${number.business_number.phone_number}..`);
			// 				track('attempting sip connect', {
			// 					numberId,
			// 					phoneNumber: number.business_number.phone_number
			// 				});
			// 				try {
			// 					await this.connectPhone(number);
			// 				} catch (e) {
			// 					logger.error(e);
			// 				}
			// 			}
			// 		}
			// 	}
			// }, 10000);
		},
		getSimpleUser() {
			return this.simpleUserInstances[this.callStatus.numberId];
		},
		getSimpleUserManagedSession() {
			// get sessions from managedsessions of incoming call if getSimpleUser()'s session is undefined.
			return this.simpleUserInstances[this.callStatus.numberId]?.sessionManager.managedSessions[0];
		},
		hideDialer() {
			// close the dialer box
			this.isCallOngoing = false;
			this.callStatus.isCallActive = false;
		},
		startTimer() {
			if (!this.callStatus._durationInterval) {
				this.callStatus.duration = 0;
				this.callStatus.showDuration = true;
				this.callStatus._durationInterval = setInterval(() => {
					this.callStatus.duration += 1;
				}, 1000);
			}
		},
		async endTimer() {
			if (this.callStatus.outgoingCallTo) {
				if (this.callStatus.duration > 5) {
					const b = await airtableService.updateRecord('successful');
				} else if (this.callStatus.duration > 0) {
					await airtableService.updateRecord('answered');
				}
			} else {
				if (this.callStatus.duration > 1) {
					await airtableInboundCallService.updateRecord('successful');
				}
			}
			if (this.callStatus._durationInterval) clearInterval(this.callStatus._durationInterval);
			this.callStatus._durationInterval = null;
			this.callStatus.showDuration = false;
			this.callStatus.duration = 0;
		},
		async beginCall(phoneNumber, requestDelegate = {}) {
			const thisStore = this;
			// phoneNumber = validateMobile(phoneNumber); // remove prefix
			const numberStore = useNumbersStore();
			const utilsStore = useUtilstStore();
			const activeNumber = numberStore.activeNumber;
			const trackPayload = {
				to: phoneNumber,
				numberId: activeNumber.id,
				phoneNumber: activeNumber.business_number?.phone_number
			};
			logger.info('call dialed - calling');
			track('call dialed', trackPayload);

			this.callStatus.outgoingCallTo = phoneNumber;

			if (this.hasInit && this.simpleUserInstances[activeNumber.id]?.isConnected()) {
				const simpleUser = this.simpleUserInstances[activeNumber.id];
				this.callStatus.numberId = activeNumber.id;
				this.callStatus.callStatusCode = null;

				if (simpleUser.session) {
					track('call dialed - session exists', trackPayload);
					await simpleUser.session.dispose();
				}

				simpleUser.call(
					`sip:${phoneNumber}@${this.sipCredentials[activeNumber.id].domain}`,
					{},
					{
						requestDelegate: {
							onAccept: (response) => {
								// the call has been accepted at the other end
								console.log(simpleUser.session, 'in');
								thisStore.callStatus.isOutgoingRinging = false;
								if (thisStore.callStatus.showDurationTimer != null) {
									clearTimeout(thisStore.callStatus.showDurationTimer);
									thisStore.callStatus.showDurationTimer = null;
								}
								thisStore.callStatus.showDuration = true;
								thisStore.callStatus.callStatusCode = response.message.statusCode;
								logger.info('call dialed - ongoing');
								track('call dialed - accepted', trackPayload);
								OUTGOING_RINGER.stop();
								this.startTimer();
							},
							onProgress: async (response) => {
								// the call is ringing on the other end
								if (
									response.message.statusCode !== 180 &&
									response.message.statusCode !== 183 &&
									response.message.statusCode !== 200
								) {
									if (utilsStore.isCallFromTest) {
										utilsStore.showTestCall = false;
										utilsStore.showFailedCall = true;
										utilsStore.isCallFromTest = false;
										engage_track(
											{ phoneNumber: activeNumber.business_number.phone_number, numberId: activeNumber.id },
											ENGAGE_EVENTS.VIEWED_RETRY_FAILED_CALL_SCREEN
										);
									}
								}
								thisStore.callStatus.callStatusCode = response.message.statusCode;
								if (thisStore.callStatus.showDurationTimer != null) {
									clearTimeout(thisStore.callStatus.showDurationTimer);
								}
								thisStore.callStatus.showDurationTimer = setTimeout(() => {
									if (!thisStore.callStatus.showDuration) {
										thisStore.callStatus.isOutgoingRinging = true;
										OUTGOING_RINGER.play();
										logger.info('call dialed - ringing');
										track('call dialed - ringing', trackPayload);
										if (!utilsStore.setUpProgressTracker.isProcessComplete) {
											track(EVENTS.TEST_CALL_SUCCESSFUL);
										}
									}
								}, 5000);
							},
							onReject: async (response) => {
								if (thisStore.callStatus.showDurationTimer != null) {
									clearTimeout(thisStore.callStatus.showDurationTimer);
									thisStore.callStatus.showDurationTimer = null;
								}
								if (!utilsStore.setUpProgressTracker.isProcessComplete) {
									setTimeout(() => {
										if (thisStore.callStatus.responseMessage?.toLowerCase().includes('busy')) {
											BUSY_PROMPT.pause();
											if (utilsStore.isCallFromTest) {
												utilsStore.showTestCall = false;
												utilsStore.showFailedCall = true;
												utilsStore.isCallFromTest = false;
												engage_track(
													{ phoneNumber: activeNumber.business_number.phone_number, numberId: activeNumber.id },
													ENGAGE_EVENTS.VIEWED_RETRY_FAILED_CALL_SCREEN
												);
											}

											return;
										} else if (thisStore.callStatus.responseMessage?.toLowerCase().includes('unavailable')) {
											UNAVAILABLE_PROMPT.pause();
											if (utilsStore.isCallFromTest) {
												utilsStore.showTestCall = false;
												utilsStore.showFailedCall = true;
												utilsStore.isCallFromTest = false;
												engage_track(
													{ phoneNumber: activeNumber.business_number.phone_number, numberId: activeNumber.id },
													ENGAGE_EVENTS.VIEWED_RETRY_FAILED_CALL_SCREEN
												);
											}
											return;
										} else {
											utilsStore.showTestCall = false;
										}
									}, 4000);
									if (!thisStore.callStatus.isOutgoingRinging) {
										track(EVENTS.TEST_CALL_REJECTED);
									}
									track(EVENTS.VIEWED_CALL_FEEDBACK);
								}
								const { reasonPhrase, statusCode } = response.message;
								thisStore.callStatus.callStatusCode = statusCode;
								thisStore.callStatus.responseMessage = reasonPhrase;
								if (reasonPhrase?.toLowerCase().includes('busy')) {
									BUSY_PROMPT.play();
								} else {
									UNAVAILABLE_PROMPT.play();
								}
								if (thisStore.callStatus.isOutgoingRinging) {
									await airtableService.updateRecord('unanswered');
								}
							},
							...requestDelegate
						}
					}
				);
			} else {
				track('failed attempt dialing call', {
					reason: 'Uninitialized dialer',
					...trackPayload
				});
				const { activeNumber } = useNumbersStore();
				try {
					await this.initDialer();
					await airtableService.updateRecord('failed');
				} catch (e) {
					logger.error(e, 'failed to initialize dialer');
				}
				notify({
					title: 'Wait..',
					text: 'Please try again in 5 seconds, the dialer has not completed' + ' initialization 😄.'
				});
				if (utilsStore.isCallFromTest) {
					utilsStore.showTestCall = false;
					utilsStore.showFailedCall = true;
					utilsStore.isCallFromTest = false;
					engage_track(
						{ phoneNumber: activeNumber.business_number.phone_number, numberId: activeNumber.id },
						ENGAGE_EVENTS.VIEWED_RETRY_FAILED_CALL_SCREEN
					);
				}
			}
		}
	}
});
