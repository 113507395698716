import { useAuthStore } from '@/stores';
import { AUTH_EXCLUDE_LIST, BASE_API } from '@/helpers/constants';
import { AuthError, PermissionError } from '@/exceptions.js';
import logger from '@/helpers/logger.js';
import { getGeolocation, getLanguage, getTimeZone, hashTimezone } from './utils';

export const fetchWrapper = {
	get: request('GET'),
	post: request('POST'),
	put: request('PUT'),
	delete: request('DELETE'),
	patch: request('PATCH'),
	download: downloadRequest,
	public_post: publicRequest('POST'),
	public_get: publicRequest('GET')
};
function request(method) {
	return async (url, body, { credentials, geofence, handleError } = {}) => {
		const requestOptions = {
			method: method,
			headers: await authHeader(url)
		};
		if (body) {
			requestOptions.headers['Content-Type'] = 'application/json';
			requestOptions.body = JSON.stringify(body);
		}
		if (credentials) {
			requestOptions.credentials = credentials;
		}
		if (geofence) {
			const geoLocation = await getGeolocation();
			if (geoLocation) {
				requestOptions.headers['X-GHSH'] = geoLocation; // only send if geoLocation is allowed
			}
			requestOptions.headers['X-TZ'] = hashTimezone(); // sends hashed TimeZone
		}

		// const usersStore = useUsersStore();
		// const utilsStore = useUtilstStore();
		// if (
		// 	usersStore.currentUser &&
		// 	(method === 'POST' || method === 'PATCH') &&
		// 	utilsStore.subscriptionStatus === 'past_due'
		// ) {
		// 	utilsStore.expiredSubscriptionModal = true;
		// 	return;
		// }

		if (handleError) {
			return fetch(url, requestOptions).then(handleErrorResponse);
		} else {
			return fetch(url, requestOptions).then(handleResponse);
		}
	};
}

function publicRequest(method) {
	return (url, body) => {
		const requestOptions = {
			method: method,
			headers: { Accept: 'application/json' },
			credentials: 'include'
		};
		if (body) {
			requestOptions.headers['Content-Type'] = 'application/json';
			requestOptions.body = JSON.stringify(body);
		}
		return fetch(url, requestOptions).then(handleResponse);
	};
}

async function downloadRequest(url, body) {
	const requestOptions = {
		method: 'GET',
		headers: await authHeader(url)
	};

	if (body) {
		requestOptions.headers['Content-Type'] = 'application/json';
		requestOptions.body = JSON.stringify(body);
	}

	return fetch(url, requestOptions)
		.then((response) => response.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(new Blob([blob]));
			const a = document.createElement('a');
			a.href = url;
			a.setAttribute('download', 'call_logs.csv');
			document.body.appendChild(a);
			a.click();
			a.remove();
		});
}

// helper functions
async function authHeader(url) {
	// return auth header with jwt if user is logged in and request is to the api url
	const authStore = useAuthStore();
	const needsAuth = !AUTH_EXCLUDE_LIST.includes(new URL(url).pathname);
	if (needsAuth) {
		// if it's not authenticated also (i.e. token is null), a 401 is fine
		const isExpired = isTokenExpired(authStore.token);
		if (isExpired) {
			try {
				// The refresh token may not be available also/expired
				const { data } = await fetchWrapper.post(`${BASE_API}/api/auth/token/refresh/`, null, {
					credentials: 'include'
				});
				authStore.setToken(data.token);
			} catch (err) {
				logger.warn(err, `Unable to refresh expired token.`);
				throw new AuthError('Error from expired token ,throwing Auth error');
			}
			// authStore.token = token;
			return {
				Authorization: `Bearer ${authStore.token}`
			};
		}

		return { Authorization: `Bearer ${authStore.token}` };
	} else {
		return {};
	}
}

async function handleResponse(response) {
	const isJson = response.headers?.get('content-type')?.includes('application/json');
	const data = isJson ? await response.json() : null;
	// check for error response
	if (!response.ok) {
		if (401 === response.status) {
			throw new AuthError('Error from handle response ,throwing Auth error');
		}
		// console.log(response);

		if (403 === response.status) {
			throw new PermissionError(JSON.stringify(data));
		}

		// get error message from body or default to response status
		const error = data ? (data.message && data.code !== '5001' ? data.message : JSON.stringify(data)) : response.status;
		return Promise.reject(error);
	}

	return {
		data,
		status: response.status
	};
}

async function handleErrorResponse(response) {
	const isJson = response.headers?.get('content-type')?.includes('application/json');
	const data = isJson ? await response.json() : null;
	// check for error response
	if (!response.ok) {
		// get error message from body or default to response status

		const error = data ? JSON.stringify(data) : response.status;
		return Promise.reject(error);
	}

	return {
		data,
		status: response.status
	};
}

const decodeToken = (token) => {
	try {
		// if the token has more or less than 3 parts or is not a string
		// then is not a valid token
		if (token.split('.').length !== 3 || typeof token !== 'string') {
			return null;
		}

		const payload = token.split('.')[1];
		const base64 = payload.replace('-', '+').replace('_', '/');
		const decoded = JSON.parse(atob(base64));

		return decoded;
	} catch (error) {
		return null;
	}
};
function getDifferenceOfDate(isoDateString1, isoDateString2) {
	const date1 = new Date(isoDateString1);
	const date2 = new Date(isoDateString2);

	// Calculate the difference in milliseconds
	const differenceInMilliseconds = date2.getTime() - date1.getTime();
	const differenceInSeconds = differenceInMilliseconds / 1000;

	return differenceInSeconds;
}
const isTokenExpired = (token) => {
	const decodedToken = decodeToken(token);
	let result = true;
	if (decodedToken && decodedToken.exp) {
		const expirationDate = new Date(0);
		expirationDate.setUTCSeconds(decodedToken.exp); // sets the expiration seconds
		// compare the expiration time and the current time
		const now = new Date();
		now.setSeconds(now.getSeconds() + 10);
		//a safe bracket of ten seconds
		// result = expirationDate.valueOf() < tommorrow.valueOf();
		result = getDifferenceOfDate(now, expirationDate) <= 0;
	} else {
		result = true;
	}

	return result;
};
