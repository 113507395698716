import mixpanel from 'mixpanel-browser';
import logger from '@/helpers/logger.js';
import { IS_PROD } from '@/helpers/constants.js';
import { track_amplitude } from './amplitude';

export const EVENTS = {
	VIEWED_SIGNIN: 'Viewed Sign In',
	REQUEST_OTP: 'Request OTP',
	THANK_UP_OTP: 'Thank You OTP',
	NUMBER_OF_CALLS_PER_WEEK: 'Number Of Calls Per Week',
	SIGNED_IN: 'Signed In',
	LOGIN_ISSUE: 'Login Issue',
	SIGNED_UP: 'Signed Up',
	OLD_USER_VIEWED_PRICING: 'Old User Viewed Pricing',
	NEW_USER_VIEWED_PRICING: 'New User Viewed Pricing',
	VIEWED_RECOMMENDED_PRICING: 'Viewed Recommended Pricing',
	VIEWED_THANK_YOU_PAGE: 'Viewed Thank You Page',
	VIEWED_OTHER_PLANS: 'Viewed other plans pricing',
	VIEWED_TEST_CALL: 'Viewed Test Call',
	VIEWED_CALL_FEEDBACK: 'Viewed Call Feedback Modal',
	CLICKED_PAY_BASIC: 'Clicked Pay for Basic',
	CLICKED_PAY_PRO: 'Clicked Pay for Pro',
	CLICKED_PAY_PLATINUM: 'Clicked Pay for Platinum',
	CLICKED_PAY_SOLOPRENEUR: 'Clicked Pay for Solopreneur',
	CLICKED_PAY_STANDARD: 'Clicked Pay for Standard',
	CLICKED_PAY_GROWTH: 'Clicked Pay for Growth',
	CLICKED_PAY_INTERNATIONAL: 'Clicked Pay for International',
		CLICKED_PAY_SOLO_QUARTERLY: 'Clicked Pay for Solo Quarterly',
	CLICKED_PAY_SOLO_ANNUAL: 'Clicked Pay for Solo Annual',
	CLICKED_PAY_STANDARD_QUARTERLY: 'Clicked Pay for Standard Quarterly',
	CLICKED_PAY_STANDARD_ANNUAL: 'Clicked Pay for Standard Annual',
		CLICKED_PAY_GROWTH_QUARTERLY: 'Clicked Pay for Growth Quarterly',
	CLICKED_PAY_GROWTH_ANNUAL: 'Clicked Pay for Growth Annual',
		CLICKED_PAY_INTERNATIONAL_QUARTERLY: 'Clicked Pay for International Quarterly',
	CLICKED_PAY_INTERNATIONAL_ANNUAL: 'Clicked Pay for International Annual',
	CLICKED_PAY: 'Clicked Pay Button',
		SELECTED_SOLO_PLAN:'Selected Solo Plan',
		SELECTED_STANDARD_PLAN:'Selected Standard Plan',
		SELECTED_GROWTH_PLAN:'Selected Growth Plan',
		SELECTED_INTERNATIONAL_PLAN:'Selected International Plan',
		SELECTED_SOLO_QUARTERLY_PLAN:'Selected Solo Quarterly Plan',
		SELECTED_STANDARD_QUARTERLY_PLAN:'Selected Standard Quarterly Plan',
		SELECTED_GROWTH_QUARTERLY_PLAN:'Selected Growth Quarterly Plan',
		SELECTED_INTERNATIONAL_QUARTERLY_PLAN:'Selected International Quarterly Plan',
		SELECTED_SOLO_ANNUAL_PLAN:'Selected Solo Annual Plan',
		SELECTED_STANDARD_ANNUAL_PLAN:'Selected Standard Annual Plan',
		SELECTED_GROWTH_ANNUAL_PLAN:'Selected Growth Annual Plan',
		SELECTED_INTERNATIONAL_ANNUAL_PLAN:'Selected International Annual Plan',
	CLICKED_THANK_YOU_BUTTON: 'Clicked Choose Your PressOne Number',
	CLICKED_TEST_CALL_BUTTON: 'Clicked Call Number Button',
	CLOSED_TEST_CALL: "Clicked I don't want to do this now",
	TEST_CALL_SUCCESSFUL: 'Test call went through',
	TEST_CALL_FAILED: 'Test call failed',
	TEST_CALL_REJECTED: 'Test call rejected',
	PORTAL_OPENED: 'Web portal Opened',
	SCREEN_VISITED: 'Screen Visited',
	CALL_DIALLED: 'Call Dialled',
	CALL_RECEIVED: 'Call Received',
	JOBS_TO_BE_DONE: 'Jobs to be done',
	BALANCE_CREDITETD: 'Balance Credited',
	SUBSCRIPTION_RENEWED: 'Subscription Renewed',
	GREETING_REQUESTED: 'Professional Greeting Requested',
	GREETING_CHANGED: 'Professional Greeting Changed',
	NUMBER_SHARED: 'Number Shared',
	PURCHASE_NUMBER: 'Number Purchased',
	NUMBER_SETUP: 'Number Setup',
	SUPPORT_REQUESTED: 'Supprt Request',
	EXTENSION_PURCHASED: 'Extension Purchased',
	ACCOUNT_DELETED: 'Delete Account',
	SUBSCRIPTION_CANCELLED: 'Subscription Cancelled',
	CALL_BOX_ON: 'Call Box On',
	CALL_BOX_OFF: 'Call Box Off',
	NEW_USER_SUBSCRIBED: 'New User Subscribed',
	BRASS_USER_SIGNUP: 'Brass User Signed up',
	SCALEIN_USER_SIGNUP: 'ScaleIn User Signed up',
	STERLING_USER_SIGNUP: 'Sterling User Signed up',
	CALL_FEEDBACK: 'Call Feedback',
	COMPLETED_PRO_GREETINGS_FOR_MOBILE: 'Completed Pro Greetings for mobile',
	PRINTIVO_USER_SIGNUP: 'Printivo User Signed up',
	SIDE_BRIEF_USER_SIGNUP: 'Side Brief User Signed up',
	VALENTINE_SPECIAL_SIGNUP: 'Sign Up - Valentine',
	AFFILIATE_SIGNUP: 'Affiliate Signed up',
};

const _MIXPANEL_TOKEN = '2c91e98b6d0b2814042994af76445638';
const _DEBUG = false;

export function track(event, prop) {
	if (IS_PROD) {
		mixpanel.init(_MIXPANEL_TOKEN, { debug: _DEBUG });
		// Object.assign incase there is some reactive value that could change before mixpanel sends
		mixpanel.track(event, Object.assign({}, prop));
		track_amplitude(event, Object.assign({}, prop))
	} else {
		logger.info(`[NON-PROD] Mixpanel-Amplitude (Track): ${event} - ${JSON.stringify(Object.assign({}, prop))}`);
	}
}

export function identify_tracked_user(email) {
	if (IS_PROD) {
		mixpanel.init(_MIXPANEL_TOKEN, { debug: _DEBUG });
		mixpanel.identify(email);
	} else {
		logger.info(`[NON-PROD] Mixpanel (Identify): ${email}`);
	}
}

export function track_error(event, error) {
	if (IS_PROD) {
		mixpanel.init(_MIXPANEL_TOKEN, { debug: _DEBUG });
		mixpanel.track(event, { error: error });
	} else {
		logger.warn(`[NON-PROD] Mixpanel (Track error): ${event} - ${JSON.stringify(error)}`);
	}
}

/**
 *
 * @param user CurrentUser object from UsersStore
 */
export function register(user) {
	//first identity user
	if (IS_PROD) {
		try {
			identify_tracked_user(user.personal_email);
		} catch (e) {
			/*do nothing*/
			logger.error(`Error identifying tracked user: ${user.personal_email}`);
		}

		// build user data from User object, and register
		mixpanel.init(_MIXPANEL_TOKEN, { debug: _DEBUG });
		let user_data = {};
		user_data = {
			email: user.personal_email,
			phone: user.mobile,
			first_name: user.first_name,
			last_name: user.last_name
		};
		try {
			if (user.team_size) user_data['team_size'] = user.team_size;
			if (user.business_name) user_data['business_name'] = user.business_name;
		} catch (e) {
			/*do nothing*/
		}

		mixpanel.register(user_data);

		// Set People profile. Untested Code. Put in try block
		try {
			user_data = {
				$email: user.personal_email,
				$phone: user.mobile,
				$first_name: user.first_name,
				$last_name: user.last_name
			};
			mixpanel.people.set(user_data);
		} catch (e) {
			/*do nothing*/
			logger.error(e, `Mixpanel set people profile error.`);
		}
	} else {
		logger.log({}, 'Ignoring mixpanel init, app not running in production.');
	}
}
