import { defineStore } from 'pinia';
import { initMessaging } from '@/firebase/init';
import { getToken } from 'firebase/messaging';
import { PINIA_PERSIST_OPTIONS } from '@/helpers';
import { engage_identify } from '@/helpers/engage';
import { useUsersStore } from './users.store';
import logger from '@/helpers/logger';
import { useRouter } from 'vue-router';

export const useFirebaseNotificationStore = defineStore({
	id: 'notification',
	state: () => ({
		deviceToken: null,
		notifications: []
	}),
	actions: {
		setDeviceToken(payload) {
			this.deviceToken = payload;
		},
		checkUserDevicePlatform() {
			const userAgent = navigator.userAgent || navigator.vendor || window.opera;
			if (/iPhone/i.test(userAgent)) {
				return 'ios';
			}
			if (/android/i.test(userAgent)) {
				return 'android';
			}
			return 'android';
		},
		addNewNotification(payload) {
			this.notifications.push(payload);
		},
		removeNotification(payload) {
			this.notifications = this.notifications.filter((item) => item.messageId !== payload);
		},
		async requestUserToken() {
			const messaging = initMessaging();
			try {
				const permission = await Notification.requestPermission();
				if (permission === 'granted' && !!messaging) {
					let fcmToken = await getToken(messaging, {
						vapidKey: 'BGv-mK1xfaCt3-waWDqGKGrnoOpGV-kX0_mC4FeeKvcLJCOtnwu8SdfpNtnrOxlWS65HlXhGvaDbe0dY_se6g1o'
					});
					this.setDeviceToken(fcmToken);
					if (this.deviceToken !== fcmToken) {
						const deviceType = this.checkUserDevicePlatform();
						engage_identify(useUsersStore().currentUser.mobile, {
							device_token: this.deviceToken,
							device_platform: deviceType
						});
					}
				}
			} catch (error) {
				logger.warn(error, 'requesting permission');
			}
		},
		handleNotificationRedirection(payload) {
			const router = useRouter();

			if (payload.data.ctaLink) {
				router.push(payload.data.ctaLink);
			}
		},
		error(message) {
			this.alert = { message, type: 'alert-danger' };
		},
		clear() {
			this.alert = null;
		}
	},
	persist: PINIA_PERSIST_OPTIONS
});
