import { defineStore } from 'pinia';

import { useUsersStore } from '@/stores/users.store';
import { formatPhoneNumber } from '@/helpers/utils';
import { PINIA_PERSIST_OPTIONS, getBusinessNumberHours,getAccess, setBusinessNumberHours, modifyAccess } from '@/helpers';
import { notify } from '@kyvg/vue3-notification';
import { useContactsStore } from './contacts.store';
import { useUtilstStore } from './utils.store';
import { useDialerStore } from './dialer.store';
import { useAuthStore } from './auth.store';
import { router } from '@/router';
import { track, EVENTS } from '@/helpers';
import { ENGAGE_EVENTS, engage_track } from '@/helpers/engage';
import { ref } from 'vue';
import logger from '@/helpers/logger';
export const useNumbersStore = defineStore({
	id: 'numbers',
	state: () => ({
		numbers: [], // this is an array of Receivers (User.receivers)
		activeNumber: null, //Receiver object
		showNumbersModal: false,
		businessHours: null
	}),
	getters: {
		activeNumberLabel: (state) =>
			state.activeNumber ? formatPhoneNumber(state.activeNumber.business_number.phone_number) : 'PressOne',
		currentUserReceiver() {
			return this.activeNumber?.id;
		},
		userIsAdmin() {
			if (this.activeNumber !== null) {
				return this.activeNumber.receiver_role.toLowerCase() !== 'user';
			}
			return false;
		}
	},
	actions: {
		async getUserPhones() {
			const userStore = useUsersStore();
			this.numbers = userStore.currentUser?.receivers;
			if (this.numbers.length && !this.activeNumber) {
				//set active number to first number if not set
				this.activeNumber = this.numbers[0];
			}
		},
		async setActiveNumber(number, refresh = true) {
			this.activeNumber = number;
			notify({
				type: 'success',
				duration: 1500,
				title: `Default number switch successful`,
				text: `Your default number has been switched to ${number.business_number.phone_number}.`
			});
			if (refresh) {
				router.go(router.currentRoute);
			}
			const contactStore = useContactsStore();
			await contactStore.fetchContacts();
		},
		async refreshActiveNumber() {
			const userStore = useUsersStore();
			let number = userStore.currentUser.receivers[0];
			await this.setActiveNumber(number, false);
		},
		// returns true if the user does NOT have an active number. It is expected to be called after getUserPhones()
		hasNoNumber() {
			return !this.activeNumber;
		},

		async getBusinessHours(activeNumberBusinessId) {
			try {
				// The response from the api call below is ridiculous though -> 'business always opened' 😄
				const response = await getAccess();
				return response;
			} catch (error) {
				console.log(error)
				logger.error(error, 'Error getting business hours:');
			}
		},

		async setBusinessHours({ schedule },voice) {
			try {
				const response = await modifyAccess({...schedule, voice});
				this.businessHours = response;
				return response;
			} catch (error) {
				logger.error(error, 'Error setting business hours:');
			}
		}
	},
	persist: PINIA_PERSIST_OPTIONS
});
