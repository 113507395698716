import { useAuthStore } from '@/stores/index.js';
import { notify } from '@kyvg/vue3-notification';
import logger from '@/helpers/logger.js';

export class AuthError extends Error {}

export class PermissionError extends Error {}

export const appExceptionHandler = async (error, instance, info) => {
	if (error instanceof AuthError) {
		const { logout } = useAuthStore();
		await logout();
	} else if (error instanceof PermissionError) {
		notify({
			title: 'Permission',
			text: 'You do not have the right permission to view this data',
			type: 'error'
		});
	} else {
		logger.error(error, info);
	}
	return false;
};
