import { defineStore } from 'pinia';

import { PINIA_PERSIST_OPTIONS } from '@/helpers';
import { useNumbersStore } from './numbers.store';
import { inject } from 'vue';
import { useDialerStore } from './dialer.store';
import { DEFAULT_UI_STATE, PressOne } from '@pressone/dialer';
import { notify } from '@kyvg/vue3-notification';
import emitter from '@/plugins/event-bus';
import { AMPLITUDE_EVENTS, track_amplitude } from '@/helpers/amplitude';

const dialerWorker = new Worker('dialer-worker.js');

export const useNewDialerStore = defineStore({
	id: 'dialer-v3',
	state: () => ({
		newSipInstance: null,
		isRegistered: {},
		sipCredentials: {},
		dialerState: DEFAULT_UI_STATE,
		isDialerOffline: false,
		connectionChecker: null,
		getActiveCallId: null,
		callDirection: null
	}),
	getters: {
		phonesConnected() {
			const numberStore = useNumbersStore();
			const total = numberStore.numbers.length;
			let connected = 0;
			if (!this.isRegistered) {
				return {
					status: false,
					connected,
					total
				};
			}
			for (const key in this.isRegistered) {
				if (this.isRegistered.hasOwnProperty(key)) {
					if (this.isRegistered[key].isConnected === true) {
						connected++;
					}
				}
			}
			return {
				status: connected === total,
				connected,
				total
			};
		},
		pressoneInstance: (state) => state.pressoneInstance
	},
	actions: {
		async fetchSipCredentials() {
			const dialerStore = useDialerStore();
			const numberStore = useNumbersStore();
			const sipCredentials = {};

			const sipInfoPromises = numberStore.numbers.map(async (number) => {
				const sipInfo = await dialerStore.getSipInfo(number);
				if (sipInfo &&
					Object.keys(sipInfo).length !== 0) {
					sipInfo.business_id = number.business_number.id;} // Add business_id key to sipInfo object being sent to the dialer package for event tracking on openobserve Only when sipInfo exist
				return { id: number.id, sipInfo };
			});

			const sipInfoResults = await Promise.all(sipInfoPromises);
			sipInfoResults
				.filter(
					(result) =>
						result.sipInfo !== null &&
						result.sipInfo !== undefined &&
						result.sipInfo !== '' &&
						Object.keys(result.sipInfo).length !== 0
				)
				.forEach((result) => {
					sipCredentials[result.id] = result.sipInfo;
				});
			return sipCredentials; // returns sipCredentials with data
		},

		async connectSipToPhone() {
			const numberStore = useNumbersStore();
			const selectedInstanceKey = numberStore?.currentUserReceiver;
			this.sipCredentials = await this.fetchSipCredentials();
			const instanceKeys = Object.keys(this.sipCredentials);
			for (let i = 0; i < instanceKeys.length; i++) {
				this.isRegistered[instanceKeys[i]] = {
					isConnected: false,
					isConnecting: true
				};
			}
			const pressone = new PressOne(
				{
					sipCredentials: this.sipCredentials
				},
				{
					debug: false,
					worker: dialerWorker,
					trackEvent: async (event) => track_amplitude(event.name, event.data)
				}
			);
			const handleUiStateUpdate = (newState) => {
				Object.assign(this.dialerState, { ...this.dialerState, ...newState });
			};

			const handleRegistration = (instanceKey, isConnected) => {
				this.isRegistered[instanceKey] = { isConnected, isConnecting: false };
				isConnected
					? track_amplitude(AMPLITUDE_EVENTS.SIP_CONNECTED)
					: track_amplitude(AMPLITUDE_EVENTS.SIP_CONNECTION_ERROR, {
							reason: `[User agent registration failure] - ${instanceKey}`
					  });
			};

			const handleUnregistration = (instanceKey) => {
				this.isRegistered[instanceKey] = { isConnected: false, isConnecting: false };
				pressone.state[instanceKey]?.userAgent?.register();
				track_amplitude(AMPLITUDE_EVENTS.SIP_DISCONNECTED, { reason: `[User agent unregistered] - ${instanceKey}` });
			};
			await pressone.start(selectedInstanceKey, instanceKeys);
			//
			pressone.onUiStateUpdate = handleUiStateUpdate;
			pressone.onRegistered = (instanceKey) => handleRegistration(instanceKey, true);
			pressone.onRegistrationFail = (instanceKey) => handleRegistration(instanceKey, false);
			pressone.onUnregistered = handleUnregistration;
			pressone.onSessionEnded = (session) => {
				this.callDirection = null;
				emitter.emit('saveNote');
			};
			pressone.onCallEnded = (session) => {
				this.callDirection = null;
			};
			pressone.onIncomingCall = () => {
				this.callDirection = 'incoming';
			};
			pressone.onOutgoingCall = () => {
				this.callDirection = 'outgoing';
			};
			this.connectionChecker = setInterval(async () => {
				if (!navigator.onLine) {
					this.isDialerOffline = true;
					await pressone.stop();
					await pressone.start(selectedInstanceKey, instanceKeys);
					notify({
						title: 'No internet connections',
						text: 'Please check your internet connection. Phone would try to reconnect in 15 secs.',
						type: 'error',
						duration: 3000
					});
					track_amplitude(AMPLITUDE_EVENTS.SIP_DISCONNECTED, { reason: `No internet connection` });
				} else {
					if (this.isDialerOffline) {
						notify({
							title: 'Back onlines!',
							text: 'Dialer is back online. Reconnecting phones...',
							duration: 5000
						});
						this.isDialerOffline = false;
					}
				}
			}, 10000);
			return pressone;
		}
	}
});
