import { defineStore } from 'pinia';
// import * as Sentry from '@sentry/vue';

import {
	fetchWrapper,
	BASE_API,
	getUserDetailsByPhone,
	PINIA_PERSIST_OPTIONS,
	updateBusinessBalance,
	getUserDetailsByToken
} from '@/helpers';
import logger from '@/helpers/logger';
import { getFirstName, getLastName } from '@/helpers/utils';

export const useUsersStore = defineStore({
	id: 'users',
	state: () => ({
		users: {},
		currentUser: null
	}),
	getters: {
		nameIncludesNumber(state) {
			return state.currentUser?.first_name.includes(234) || state.currentUser?.last_name.includes(234)
		},
		firstName(state) {
			// get first name from recievers object	when first_name returns phone number
			return getFirstName(state.currentUser?.receivers[0]?.receiver_name);
		},
		lastName(state) {
			// get last name from recievers object  when lasst_name returns phone number
			return getLastName(state.currentUser?.receivers[0]?.receiver_name);
		},
		businessName(state){
			return state.currentUser?.receivers[0]?.business_name 
		}
	},
	actions: {
		async getAll() {
			this.users = { loading: true };
			try {
				this.users = await fetchWrapper.get(BASE_API);
			} catch (error) {
				logger.error(error, 'Error in fetching users method, users actions');
				throw error;
			}
		},
		async loadCurrentUser(mobile_number) {
			this.currentUser = await getUserDetailsByToken();
			// this.currentUser contains all user properties. E.g. this.currentUser.first_name
		},
		async updateBusinessBalance() {
			logger.info('updateBusinessBalance');
			try {
				if (!this.currentUser) return;
				const data = await updateBusinessBalance();
				this.currentUser.balance = data?.balance;
			} catch (error) {
				logger.error(error, 'Error in updateBusinessBalance method, users actions');
			}
		}
	},
	persist: PINIA_PERSIST_OPTIONS
});
