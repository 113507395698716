import { fetchWrapper } from '@/helpers/fetch-wrapper';
import { BASE_API, TELEPHONY_BASE_API } from '@/helpers/constants';
import { useAuthStore, useNumbersStore, useUsersStore } from '@/stores';

const getActiveBusinessId = () => {
	const numberStore = useNumbersStore();
	if (!numberStore.activeNumber) {
		return '';
	}
	return numberStore.activeNumber.business_number.id;
};

const getUserId = () => {
	const userStore = useUsersStore();
	if (!userStore.currentUser) {
		return '';
	}
	return userStore.currentUser.id;
};

const parseData = async (response) => {
	return response instanceof Promise ? (await response)?.data : response?.data;
	// return response instanceof Promise ? { data: (await response)?.data, status: response.status } : { data: response.data, status: response.status };
};

export const verifyLoginToken = async (payload) => {
	return await fetchWrapper.post(`${BASE_API}/api/auth/token/`, payload, {
		credentials: 'include'
	});
};

export const registerUser = async (payload) => {
	return await fetchWrapper.post(`${BASE_API}/api/users/register/`, payload);
};

export const refreshToken = async () => {
	return await fetchWrapper.post(`${BASE_API}/api/auth/token/refresh/`);
};

export const requestLoginOTP = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/login/`, payload));
};
export const encryptKey = async (payload) => {
	return parseData(await fetchWrapper.public_post(`${BASE_API}/api/v2/auth/encryption-key/`, payload));
};
export const verifyEncryptedLoginToken = async (payload) => {
	return parseData(await fetchWrapper.public_post(`${BASE_API}/api/v2/auth/verify-otp`, payload));
};

export const getUserDetailsByPhone = async (mobile_phone) => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/users/by_mobile/?mobile=${mobile_phone}`, null));
};

export const getUserDetailsByToken = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/users/me/`));
};

export const fetchUserCalls = async (link, pageSize = '') => {
	if (link) {
		const url = new URL(link);
		link = url.pathname + url.search;
		return parseData(await fetchWrapper.get(`${BASE_API}${link}`, null));
	} else {
		return parseData(
			await fetchWrapper.get(
				`${BASE_API}/api/telephony/calls/?number=${getActiveBusinessId()}&page_size=${pageSize}`,
				null
			)
		);
	}
};
export const filterUserCalls = async (filter) => {
	const filterString = Object.keys(filter)
		.map((key) => `${key}=${filter[key]}`)
		.join('&');
	return parseData(
		await fetchWrapper.get(`${BASE_API}/api/telephony/calls/?number=${getActiveBusinessId()}&${filterString}`, null)
	);
};

export const fetchUserCallsReport = async (data) => {
	let url;
	if (data.custom_start_date && data.custom_end_date) {
		url = `${BASE_API}/api/telephony/calls/report/
		?number=${data.number}&period=${data.period}&custom_start_date=${data.custom_start_date}&custom_end_date=${data.custom_end_date}`;
	} else {
		url = `${BASE_API}/api/telephony/calls/report/
		?number=${data.number}&period=${data.period}`;
	}
	return parseData(fetchWrapper.get(url));
};

export const sortUserCallsByDate = async (data, with_notes, pageSize = '') => {
	return parseData(
		await fetchWrapper.get(
			`${BASE_API}/api/telephony/calls/?number=${getActiveBusinessId()}&from_date=${data.from_date}&to_date=${
				data.to_date
			}&with_note=${with_notes}&page_size=${pageSize}`
		)
	);
};

export const sortUserCallsByPhone = async (phone_number, with_notes, pageSize = '') => {
	return parseData(
		await fetchWrapper.get(
			`${BASE_API}/api/telephony/calls/?number=${getActiveBusinessId()}&phone_number=${phone_number}&with_note=${with_notes}&page_size=${pageSize}`
		)
	);
};

export const sortUserCallsByAgent = async (user_id, with_notes, pageSize = '') => {
	return parseData(
		await fetchWrapper.get(
			`${BASE_API}/api/telephony/calls/?number=${getActiveBusinessId()}&user_id=${user_id}&with_note=${with_notes}&page_size=${pageSize}`
		)
	);
};

export const fetchUserContacts = async (page = 1) => {
	return parseData(
		await fetchWrapper.get(`${BASE_API}/api/contacts/?page=${page}&business_number_id=${getActiveBusinessId()}`, null)
	);
};

export const addNewContact = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/contacts/`, payload));
};

export const updateContact = async (id, payload) => {
	return parseData(
		await fetchWrapper.patch(`${BASE_API}/api/contacts/${id}/?business_number_id=${getActiveBusinessId()}`, payload)
	);
};

export const deleteContact = async (id) => {
	return parseData(await fetchWrapper.delete(`${BASE_API}/api/contacts/${id}/?business_number_id=${getActiveBusinessId()}`));
};

export const addNewContacts = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/contacts/upload_contacts/`, payload));
};

export const setCallForwardingNumber = async (id, payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/numbers/${id}/set_forward_number/`, payload));
};
 
export const fetchCallNotes = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/call_notes/?business_id=${getActiveBusinessId()}`));
};

export const fetchUserNumbers = async (page = 1) => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/numbers/?page=${page}`));
};

export const fetchTeamMembers = async () => {
	return parseData(
		await fetchWrapper.get(`${BASE_API}/api/receiver_numbers/?business_number=${getActiveBusinessId()}`)
	);
};

export const fetchUserStatus = async (id) => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/receiver_numbers/${id}/sip-device-status/`));
};

export const fetchTeamMembersStatus = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/numbers/${getActiveBusinessId()}/team-members-status/`, payload));
};

export const updateTeamMemberStatus = async (id, payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/receiver_numbers/${id}/sip-device-status/`, payload));
};

export const addNewTeamMember = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/receiver_numbers/`, payload));
};

export const addNewTeamMemberMultiple = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/create-receivers/`, payload));
};

export const updateTeamMember = async (id, payload) => {
	return parseData(await fetchWrapper.patch(`${BASE_API}/api/receiver_numbers/${id}/`, payload));
};

export const updateTeamMemberPermission = async (id, payload) => {
	return parseData(await fetchWrapper.patch(`${BASE_API}/api/receiver_numbers/${id}/update_permissions/`, payload));
};

export const deleteTeamMember = async (id) => {
	return parseData(await fetchWrapper.delete(`${BASE_API}/api/receiver_numbers/${id}/`));
};

export const updateTeamMemberAsPrimary = async (id) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/set_primary_receiver/${id}/`, null));
};

export const updateUserProfile = async (id, payload) => {
	return parseData(await fetchWrapper.patch(`${BASE_API}/api/users/${id}/`, payload));
};

export const updateBusinessBalance = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/business/number/${getActiveBusinessId()}/balance/`));
};

export const isCardExist = async (id) => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/users/${id}/cards/`));
};
export const deleteCard = async (id, payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/users/${id}/delete_cards/`, payload));
};

export const chargeCardAuth = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/v2/top_up/charge_card_authorization/`, payload));
};
export const chargeNewCard = async (payload) => {
	return parseData(
		await fetchWrapper.post(`${BASE_API}/api/v2/top_up/`, {
			...payload,
			payment_mode: 'CARD'
		})
	);
};
export const verifyTopUpPayment = async (ref) => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/purchase/status/${ref}/`));
};
export const fetchGreetings = async () => {
	// return parseData(await fetchWrapper.get(`${BASE_API}/api/get_accents/`));
	return parseData(await fetchWrapper.get(`${BASE_API}/api/request_greetings/list_greetings/`));
};
// export const fetchVoicesForStaging = async () => {
// 	return parseData(await fetchWrapper.get(`${BASE_API}/api/get_accents/`));
// };
// export const fetchVoicesForStaging = async () => {
// 	return parseData(await fetchWrapper.get(`${BASE_API}/api/get_accents/`));
// };
export const fetchProfessionalGreetings = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/voice_scripts/?business_number=${getActiveBusinessId()}`));
};
export const saveBasicGreeting = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/request_greetings/request_automated/`, payload));
};
export const toggleGreeting = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/toggle_voice_scripts_status/`, payload));
};

export const buyAdditionalNumberExtensions = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/buy_additional_number_extensions/`, payload));
};
export const authBuyAdditionalNumberExtensions = async (payload) => {
	return parseData(
		await fetchWrapper.post(`${BASE_API}/api/v2/buy_additional_number_extensions/charge_card_authorization/`, payload)
	);
};

export const fetchSipInfo = async (payload) => {
	return parseData(
		await fetchWrapper.post(`${BASE_API}/api/telephony/crm/receiver-line/`, payload, {
			geofence: true,
			handleError: true
		})
	);
};

export const saveUserReferrals = async (payload) => {
	return await fetchWrapper.post(`${BASE_API}/api/user_referrals/`, {
		referral_contacts: [...payload]
	});
};

export const getPlans = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/subscription_plans/`));
};
export const getUnPaidPlanBreakdown = async (business_id) => {
	return parseData(
		await fetchWrapper.get(`${BASE_API}/api/payments/invoice/last_unpaid_invoice/?business_id=${business_id}`)
	);
};
export const getRenewSubscriptionPaymentLink = async (invoice_id) => {
	return `${BASE_API}/api/payments/invoice/pay/?invoice_number=${invoice_id}`;
};

export const getWebPaymentPurchaseLink = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/v2/web-purchase/`, payload));
};

export const getWebPaymentPurchaseLinkSignUp = async (payload) => {
	return parseData(await fetchWrapper.public_post(`${BASE_API}/api/v2/web-purchase/`, payload));
};

export const numberPurchase = async (payload) => {
	const authStore = useAuthStore();
	if (authStore.isAuthenticated) {
		return parseData(await fetchWrapper.post(`${BASE_API}/api/v2/web-purchase/`, payload));
	} else {
		return parseData(await fetchWrapper.public_post(`${BASE_API}/api/v2/web-purchase/`, payload));
	}
};

export const authenticatedNumberPurchase = async (payload) => {
	// const authStore = useAuthStore();
	// if (authStore.isAuthenticated) {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/v2/purchase/`, payload));
	// } else {
	// 	return parseData(await fetchWrapper.public_post(`${BASE_API}/api/v2/purchase/`, payload));
	// }
};

export const provisionNumber = async (payload) => {
	return await fetchWrapper.post(`${BASE_API}/api/telephony/provision-number/`, payload);
};

export const getAvailableMobileNumbers = async (country_code) => {
	return parseData(
		await fetchWrapper.get(`${BASE_API}/api/available-numbers/?country=${country_code}&number_type=mobile`)
	);
};

export const getAvailableLandlineNumbers = async (country_code) => {
	return parseData(
		await fetchWrapper.get(`${BASE_API}/api/available-numbers/?country=${country_code}&number_type=landline`)
	);
};

export const getPendingNumberPayment = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/v2/business_number_payment/`));
};

export const getHubSpotToken = async (code) => {
	return parseData(await fetchWrapper.get(`${TELEPHONY_BASE_API}/api/v1/hubspot/oauth/?code=${code}`));
};

export const getPipeDriveToken = async (code) => {
	return parseData(await fetchWrapper.get(`${TELEPHONY_BASE_API}/api/v1/pipedrive/oauth/?code=${code}`));
};

export const getIntercomToken = async (code) => {
	return parseData(await fetchWrapper.get(`${TELEPHONY_BASE_API}/api/v1/intercom/oauth/?code=${code}`));
};

export const getInstalledApp = async () => {
	return parseData(await fetchWrapper.get(`${TELEPHONY_BASE_API}/api/v1/accounts/`));
};

export const uninstallZohoPhoneBridge = async () => {
	return parseData(await fetchWrapper.delete(`${TELEPHONY_BASE_API}/api/v1/accounts/phonebridge/`));
};

export const uninstallHubspot = async () => {
	return parseData(await fetchWrapper.delete(`${TELEPHONY_BASE_API}/api/v1/accounts/hubspot/`));
};

export const uninstallPipedrive = async () => {
	return parseData(await fetchWrapper.delete(`${TELEPHONY_BASE_API}/api/v1/accounts/pipedrive/`));
};

export const uninstallIntercom = async () => {
	return parseData(await fetchWrapper.delete(`${TELEPHONY_BASE_API}/api/v1/accounts/intercom/`));
};

export const activateZohoPhoneBridgeNumber = async (payload) => {
	return parseData(await fetchWrapper.post(`${TELEPHONY_BASE_API}/api/v1/zoho/phonebridge/active-number/`, payload));
};

export const updateBusinessWidget = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/v1/widget/`, payload));
};

export const getBusinessWidget = async () => {
	return parseData(
		await fetchWrapper.get(`${BASE_API}/api/v1/widget/business-number/?business_number_id=${getActiveBusinessId()}`)
	);
};

export const generateApiKey = async () => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/business/${getActiveBusinessId()}/keys/`));
};

export const getApiKey = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/business/${getActiveBusinessId()}/keys/`));
};

export const getAllBusiness = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/business`));
};
export const addNewCallNote = async (payload, useCallLogEndpoint) => {
	const withCallId = `${BASE_API}/api/telephony/call-notes/`;
	const withCallLog = `${BASE_API}/api/telephony/call-notes/with-log/`;
	return parseData(await fetchWrapper.post(useCallLogEndpoint ? withCallLog : withCallId, payload));
};

export const updateCallNote = async (payload, pk) => {
	return parseData(await fetchWrapper.patch(`${BASE_API}/api/telephony/call-notes/id/${pk}/`, payload));
};

export const addNewContactNote = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/telephony/call-notes/`, payload));
};

export const updateContactNote = async (payload, pk) => {
	return parseData(await fetchWrapper.patch(`${BASE_API}/api/telephony/call-notes/id/${pk}/`, payload));
};

export const downloadCallLog = async (from, to, user_id) => {
	const withUserID = `${BASE_API}/api/telephony/calls/download/?from_date=${from}&number=${getActiveBusinessId()}&to_date=${to}&user_id=${user_id}`;
	const withoutUserID = `${BASE_API}/api/telephony/calls/download/?from_date=${from}&number=${getActiveBusinessId()}&to_date=${to}`;
	return parseData(await fetchWrapper.download(user_id ? withUserID : withoutUserID));
};

export const changeRingGroupPosition = async (payload) => {
	return parseData(
		await fetchWrapper.post(`${BASE_API}/api/numbers/${getActiveBusinessId()}/modify_ring_group/`, payload)
	);
};

export const publishNumber = async (business_number, payload) => {
	return parseData(
		await fetchWrapper.post(`${BASE_API}/api/numbers/${business_number}/broadcast_business_number_on_channel/`, payload)
	);
};

export const getBusinessNumberHours = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/business_hours/${getActiveBusinessId()}/`));
};

export const setBusinessNumberHours = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/business_hours/${getActiveBusinessId()}/`, payload));
};

export const resetBusinessNumberHours = async () => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/business_hours/${getActiveBusinessId()}/reset_hours`));
};

export const setBusinessNumberHoursAlwaysOpen = async () => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/business_hours/${getActiveBusinessId()}/set_always_open/`));
};

export const inactiveBusiness = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/numbers/inactive_business_numbers/`));
};

export const getUserSubscription = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/users/me/`));
};
export const getUserSubscriptionStatus = async (businessId) => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/subscriptions/status/?business_number_id=${businessId}`));
};
export const updateBusinessSubscription = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/v2/update_business_number_subscription/`, payload));
};
export const cancelSubscription = async (business_number, payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/numbers/${business_number}/cancel_subscription/`, payload));
};

export const renewBusiness = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/v2/renew_business_number_subscription/`, payload));
};

export const renewBusinessCard = async (payload) => {
	return parseData(
		await fetchWrapper.post(`${BASE_API}/api/v2/renew_business_number_subscription/charge_card_authorization/`, payload)
	);
};

export const fetchIVR = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/numbers/${getActiveBusinessId()}/ivr/`));
};

export const createIVR = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/numbers/${getActiveBusinessId()}/ivr/`, payload));
};

export const sendRecommendation = async (payload) => {
	return parseData(await fetchWrapper.public_post(`${BASE_API}/api/v1/recommendation/`, payload));
};
export const getRecommendation = async (payload) => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/v1/recommendation/?mobile=${payload}`));
};

// export const updateIVR = async (payload) => {
// 	return parseData(await fetchWrapper.patch(`${BASE_API}/api/numbers/${getActiveBusinessId()}/ivr/`, payload));
// };

export const deleteIVR = async () => {
	return parseData(await fetchWrapper.delete(`${BASE_API}/api/numbers/${getActiveBusinessId()}/ivr-menu/`));
};

export const customRequest = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/new_issue/`, payload));
};

export const updateUserSession = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/team_activities/update_session_activities`));
};

export const confirmTransaction = async (ref, id) => {
	return parseData(await fetchWrapper.public_get(`${BASE_API}/api/purchase/status/${ref}?user_id=${id}`));
};
export const getCancelSubscriptionOtp = async (payload) => {
	return parseData(await fetchWrapper.public_post(`${BASE_API}/api/v2/subscription/cancel_subscription/`, payload));
};
export const cancelSubscriptionVerification = async (payload) => {
	return parseData(
		await fetchWrapper.public_post(`${BASE_API}/api/v2/subscription/cancel_subscription_verification/`, payload)
	);
};
// export const upgradeBusiness = async (payload) => {
// 	return parseData(await fetchWrapper.post(`${BASE_API}/api/v2/upgrade_business_number_subscription/`, payload));
// };

// Voice Mail
// Voice Mail
export const fetchVoicemail = async (params, link) => {
	if (!link) {
		const { page = 1, date_created__range = '', status = '', caller_id = '' } = params;
		const queryParams = new URLSearchParams({
			type: 'voicemail',
			business_number_id: getActiveBusinessId(),
			page,
			date_created__range,
			listened: status ? (status === 'new' ? false : true) : '',
			caller_id,
			page_size: ''
		});

		const url = `${BASE_API}/api/voice_messages/?${queryParams}`;

		return parseData(await fetchWrapper.get(url, null));
	} else {
		return parseData(await fetchWrapper.get(link, null));
	}
};
export const updateVoicemailStatus = async (voiceId) => {
	const url = `${BASE_API}/api/voice_messages/${voiceId}`;

	return parseData(await fetchWrapper.patch(url, 'listened'));
};
export const fetchAgentReport = async (params, id) => {
	const { order_by = '-last_login', period = 'this_week', custom_start_date = '', custom_end_date = '' } = params;
	const queryParams = new URLSearchParams({
		order_by,
		period,
		custom_start_date,
		custom_end_date
	});
	return parseData(
		await fetchWrapper.get(`${BASE_API}/api/numbers/${getActiveBusinessId()}/team-members-report/?${queryParams}`)
	);
};
export const setRequestGreetings = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/request_greetings/request_automated/`, payload));
};
export const getVoiceScript = async (type, active) => {
	return parseData(
		await fetchWrapper.get(
			`${BASE_API}/api/voice_scripts/?business_number=${getActiveBusinessId()}&type=${type}&is_active=${active}`,
			null
		)
	);
};
export const removeVoiceScript = async (id) => {
	return parseData(await fetchWrapper.delete(`${BASE_API}/api/voice_scripts/?id=${id}`));
};
export const modifyRingGroup = async (payload) => {
	return parseData(
		await fetchWrapper.patch(`${BASE_API}/api/numbers/${getActiveBusinessId()}/modify-ring-group/`, payload)
	);
};
export const modifyAccess = async (payload) => {
	return parseData(
		await fetchWrapper.patch(`${BASE_API}/api/numbers/${getActiveBusinessId()}/modify-time-access/`, payload)
	);
};
export const getAccess = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/numbers/${getActiveBusinessId()}/time-access/`, null));
};
export const modifyPrompt = async (payload) => {
	return parseData(
		await fetchWrapper.patch(`${BASE_API}/api/numbers/${getActiveBusinessId()}/modify-prompt/`, payload)
	);
};
export const updateIVRScriptMenu = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/numbers/${getActiveBusinessId()}/ivr-menu/`, payload));
};
export const fetchPrevIVR = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/numbers/${getActiveBusinessId()}/ivr-detail/`));
};

export const setNewIVR = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/numbers/${getActiveBusinessId()}/ivr-menu/`, payload));
};
export const setNewIVREntry = async (payload, parent_menu) => {
	return parseData(
		await fetchWrapper.post(`${BASE_API}/api/numbers/${getActiveBusinessId()}/ivr-menu-entry/`, payload)
	);
};

export const getRingGroup = async () => {
	return parseData(await fetchWrapper.get(`${BASE_API}/api/numbers/${getActiveBusinessId()}/ring-group/`));
};

export const modifyCallDestination = async (payload) => {
	console.log(payload);
	return parseData(
		await fetchWrapper.patch(`${BASE_API}/api/numbers/${getActiveBusinessId()}/modify-call-destination/`, payload)
	);
};
export const fetchGreetingsAccent = async () => {
	// return parseData(await fetchWrapper.get(`${BASE_API}/api/get_accents/`));
	return parseData(await fetchWrapper.get(`${BASE_API}/api/request_greetings/list_greetings/`));
};

export const verifyAccount = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/users/request_verification/`, payload));
};

export const requestVerificationOTP = async () => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/users/request_verification_otp/`));
};

export const confirmVerificationOTP = async (payload) => {
	return parseData(await fetchWrapper.post(`${BASE_API}/api/users/confirm_verifation_otp/`, payload));
};

export const getAffliateDataWithSignature = async (signature) => {
	return parseData(await fetchWrapper.get(`https://affiliate-dummy-api-testing.up.railway.app/api/v1/affiliate/links/use/${signature}`));
}

export const recordSignupAttempt = async (ref) => {
	return parseData(await fetchWrapper.public_get(`${BASE_API}/api/v1/affiliate/links/use/${ref}`));
}
