import { createRouter, createWebHistory } from 'vue-router';
import * as Sentry from '@sentry/vue';
import openReplay from '../helpers/openReplay.js';
import { useAuthStore, useAlertStore, useUsersStore, useUtilstStore, useNumbersStore } from '@/stores';
const LazySignInView = () => import('@/views/SignInView.vue');
const LazySignUpView = () => import('@/views/SignUpView.vue');
const LazyHomeView = () => import('@/views/HomeView.vue');
const LazyBaseHomeView = () => import('@/views/BaseHomeView.vue');
const LazyContactsView = () => import('@/views/ContactsView.vue');
const LazyVoicemailView = () => import('@/views/VoicemailView.vue');
const LazyCallsView = () => import('@/views/CallsView.vue');
const LazyTeamView = () => import('@/views/TeamView.vue');
const LazyAgentReportingView = () => import('@/views/AgentReportingView.vue');
const LazyPricingPlanView = () => import('@/views/PricingPlanView.vue');
const LazyAffiliateView = () => import('@/views/AffiliateView.vue');
// const LazyPricingPlanView2 = () => import('@/views/PricingPlanView2.vue');
const LazyRecommendedPlanView = () => import('@/views/RecommendedPlanView.vue');
const LazyProfessionalGreeting = () => import('@/views/ProfessionalGreeting.vue');
const LazyCompleteSetUpView = () => import('@/views/CompleteSetUpView.vue');
const LazyCompleteSignUpView = () => import('@/views/CompleteSignUpView.vue');
const LazySubscriptionView = () => import('@/views/SubscriptionView.vue');
const LazyOfficeHours = () => import('@/views/OfficeHours.vue');
/* Integrations */
const LazyIntegration = () => import('@/views/Integration.vue');
const LazyIntegrationZohoCRM = () => import('@/views/IntegrationZohoCRMGuide.vue');
const LazyIntegrationZohoPhoneBridge = () => import('@/views/IntegrationZohoPhoneBridgeGuide.vue');
const LazyIntegrationCustomerWidget = () => import('@/views/IntegrationCustomerWidget.vue');
const LazyIntegrationZapier = () => import('@/views/IntegrationZapier.vue');
const LazyIntegrationHubspot = () => import('@/views/IntegrationHubspotGuide.vue');
const LazyIntegrationPipeDrive = () => import('@/views/IntegrationPipeDriveGuide.vue');
const LazyIntegrationIntercom = () => import('@/views/IntegrationIntercomGuide.vue');
const LazyIntegrationChromeExtension = () => import('@/views/IntegrationChromeExtensionGuide.vue');
const LazyIntegrationFreshdesk = () => import('@/views/IntegrationsFreshdeskGuide.vue');
const LazyIntegrationZendesk = () => import('@/views/IntegrationsZendeskGuide.vue');
const LazyThankYouForPaymentViewVue = () => import('@/views/ThankYouForPaymentView.vue');
const LazyGrowYourBusinessViewVue = () => import('@/views/GrowYourBusinessView.vue');
const LazyRenewSubscriptionView = () => import('@/views/RenewSubscriptionView.vue');
const LazyBrassHomeView = () => import('@/views/BrassHomeView.vue');
const LazyResetSetUpView = () => import('@/views/ResetSetUpView.vue');
const LazyCancelSubscriptionView = () => import('@/views/CancelSubscriptionView.vue');
const LazyMigrationRequestView = () => import('@/views/MigrationRequestView.vue');

export const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	linkActiveClass: 'active',
	routes: [
		{
			path: '/',
			name: 'main',
			component: LazyBaseHomeView,
			redirect: '/home',
			children: [
				{
					path: 'home',
					name: 'HomeView',
					component: LazyHomeView
				},
				{
					path: 'voicemail',
					name: 'VoiceMailView',
					component: LazyVoicemailView
				},
				{
					path: 'contacts',
					name: 'ContactsView',
					component: LazyContactsView
				},
				{
					path: 'calls',
					name: 'CallsView',
					props: (route) => ({ start_date: route.query.start_date }, { end_date: route.query.end_date }),
					component: LazyCallsView
				},
				{
					path: 'team',
					name: 'TeamView',
					props: (route) => ({ pending_member: route.query.pending_member }),
					component: LazyTeamView
				},
				{
					path: 'agent-report',
					name: 'AgentReportingView',
					component: LazyAgentReportingView
				},
				{
					path: 'pricing',
					name: 'PricingPlanView',
					component: LazyPricingPlanView
				},
				{
					path: 'pricing/:id',
					component: LazyPricingPlanView
				},
				{
					path: 'professional_greeting',
					name: 'ProfessionalGreeting',
					component: LazyProfessionalGreeting
				},
				{
					path: '/complete-setup',
					name: 'CompleteSetUpView',
					props: (route) => ({ source: route.query.source }),
					component: LazyCompleteSetUpView
				},
				{
					path: '/reset-setup',
					name: 'ResetSetUpView',
					component: LazyResetSetUpView
				},
				{
					path: '/brass-services',
					name: 'BrassHomeView',
					component: LazyBrassHomeView
				},
				{
					path: '/renew-subscription',
					name: 'RenewSubscriptionView',
					props: (route) => ({ redirect: route.query.redirect }),
					component: LazyRenewSubscriptionView
				},
				// {
				// 	path: '/publish-number',
				// 	component: PublishNumberView
				// },
				{
					path: '/subscription',
					component: LazySubscriptionView
				},
				{
					path: '/office_hours',
					name: 'OfficeHours',
					component: LazyOfficeHours
				},
				{
					path: '/grow_your_business',
					name: 'GrowYourBusiness',
					component: LazyGrowYourBusinessViewVue
				},
				/* Integrations */
				{
					path: '/integration',
					name: 'Integration',
					component: LazyIntegration
				},
				{
					path: '/callback/hubspot',
					// name: 'IntegrationHubspot',
					component: LazyIntegration
				},
				{
					path: '/callback/pipedrive',
					// name: 'IntegrationPipedrive',
					component: LazyIntegration
				},
				{
					path: '/callback/intercom',
					// name: 'IntegratIonintercom',
					component: LazyIntegration
				},
				{
					path: 'integration-zoho-crm',
					name: 'IntegrationZohoCRM',
					component: LazyIntegrationZohoCRM
				},
				{
					path: 'integration-zoho-phonebridge',
					name: 'IntegrationZohoPhoneBridge',
					component: LazyIntegrationZohoPhoneBridge
				},
				{
					path: 'integration-pressone-widget',
					name: 'IntegrationCustomerWidget',
					component: LazyIntegrationCustomerWidget
				},
				{
					path: 'integration-zapier',
					name: 'LazyIntegrationZapier',
					component: LazyIntegrationZapier
				},
				{
					path: 'integration-freshdesk',
					name: 'IntegrationFreshdesk',
					component: LazyIntegrationFreshdesk
				},
				{
					path: 'integration-zendesk',
					name: 'IntegrationZendesk',
					component: LazyIntegrationZendesk
				},
				{
					path: 'integration-hubspot-crm',
					name: 'IntegrationHubspot',
					component: LazyIntegrationHubspot
				},
				{
					path: 'integration-google-chrome-extension',
					name: 'IntegrationChromeExtension',
					component: LazyIntegrationChromeExtension
				},
				{
					path: 'integration-pipedrive',
					name: 'IntegrationPipeDrive',
					component: LazyIntegrationPipeDrive
				},
				{
					path: 'integration-intercom',
					name: 'IntegrationIntercom',
					component: LazyIntegrationIntercom
				}
			]
		},
		{
			path: '/sign-in',
			name: 'sign-in',
			component: LazySignInView
		},
		{
			path: '/thank-you',
			name: 'thank-you',
			component: LazyThankYouForPaymentViewVue
		},
		{
			path: '/sign-up',
			name: 'sign-up',
			component: LazySignUpView
		},

		{
			path: '/complete-signup',
			name: 'CompleteSignUpView',
			component: LazyCompleteSignUpView,
			meta: {
				authRequired: false
			}
		},
		{
			path: '/recommended-pricing',
			name: 'RecommendedPricingPlanView',
			props: (route) => ({ id: route.query.id, type: route.query.type, source: route.query.source }),
			component: LazyRecommendedPlanView
		},
		{
			path: '/cancel-subscription',
			name: 'CancelSubscriptionView',
			props: (route) => ({ id: route.query.id }),
			component: LazyCancelSubscriptionView
		},
		{
			path: '/migration-request',
			name: 'MigrationRequestView',
			props: (route) => ({ ref: route.query.ref }),
			component: LazyMigrationRequestView
		},
		{
			path: '/affiliate',
			name: 'AffiliateView',
			component: LazyAffiliateView
		},
		// catch all redirect to home page
		{ path: '/:pathMatch(.*)*', redirect: '/home' }
	]
});

export const publicPages = ['/sign-in', '/pricing', '/sign-up', '/complete-signup'];

router.beforeEach(async (to, from) => {
	Sentry.configureScope((scope) => scope.setTransactionName(to?.meta?.label));

	// clear alert on route change
	const alertStore = useAlertStore();
	alertStore.clear();

	const pageToTrack = [
		'/sign-up',
		'/pricing',
		'/home',
		'/calls',
		'/complete-setup',
		'/sign-in',
		'/complete-signup',
		'/recommended-pricing',
		'/thank-you'
	];

	// redirect to login page if not logged in and trying to access a restricted page
	const publicPages = [
		'/sign-in',
		'/pricing',
		'/sign-up',
		'/complete-signup',
		'/recommended-pricing',
		'/thank-you',
		'/cancel-subscription',
		'/migration-request',
		'/affiliate'
	];
	const authRequired = !publicPages.includes(to.path);
	const authStore = useAuthStore();
	const userStore = useUsersStore();
	const utilsStore = useUtilstStore();
	const numberStore = useNumbersStore();
	const { currentUser } = useUsersStore();
	// Check if token exists in local storage
	const token = localStorage.getItem('token');
	if (token && !authStore.isAuthenticated) {
		authStore.token = token;
		await userStore.loadCurrentUser();
	}
	if (authRequired && !authStore.isAuthenticated) {
		const queryParams = new URLSearchParams(window.location.search);
		const utmSource = queryParams.get('utm_source');
		if (utmSource) {
			return '/sign-up' + '?utm_source=' + utmSource;
		}
		authStore.returnUrl = to.fullPath;
		if (utilsStore.numberPurchaseMode) {
			return '/thank-you';
		} else {
			return '/sign-in';
		}
	}

	if (
		// currentUser?.verification?.status != 'verified' &&
		numberStore.activeNumber?.business_number?.verification_status === 'verification_required' ||
		numberStore.activeNumber?.business_number?.verification_status === 'verification_pending' ||
		numberStore.activeNumber?.business_number?.verification_status === 'access_revoked' ||
		numberStore.activeNumber?.business_number?.verification_status === 'mobile_mismatch'
		// && currentUser?.business.map((b) => b.owner).includes(currentUser?.id) &&
		// (userStore?.currentUser?.receivers[0]?.business_number?.verification_status === 'verification_required' ||
		// 	userStore?.currentUser?.receivers[0]?.business_number?.verification_status === 'access_revoked'||
		// 	userStore?.currentUser?.receivers[0]?.business_number?.verification_status === 'mobile_mismatch')
	) {
		utilsStore.showVerificationBanner = true;
	} else {
		utilsStore.showVerificationBanner = false;
	}
	// console.log(numberStore.activeNumber)
	if (to.path == '/integration' && authStore.isAuthenticated) {
		const inValidPlans = [25, 26, 31, 32, 33, 34];
		if (!inValidPlans.includes(numberStore.activeNumber.business_number.plan)) {
			return '/home';
		}
	}

	if (from.path === '/pricing' && utilsStore.numberPurchaseMode && currentUser.receivers.length > 0) {
		utilsStore.numberPurchaseMode = false;
		return;
	}

	if (
		(to.fullPath == '/thank-you' && authStore.isAuthenticated) ||
		(to.fullPath == '/thank-you' && !utilsStore.numberPurchaseMode)
	) {
		return '/';
	}

	if (pageToTrack.includes(to.path)) {
		if (currentUser) {
			openReplay.setUserID(`${currentUser.personal_email}`);
		}
		openReplay.start();
	} else {
		openReplay.stop();
	}
});
router.onError(() => {
	// console.log('Got Here');
	// location.reload();
});
