import JSEncrypt from 'jsencrypt';
let exportPemKey = async (key) => {
	let exported = await window.crypto.subtle.exportKey('spki', key);
	let exportedAsBase64 = btoa(String.fromCharCode.apply(null, new Uint8Array(exported)));
	return `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;
};

export const generateKeyPair = async () => {
	let keys = await window.crypto.subtle.generateKey(
		{
			name: 'RSA-OAEP',
			modulusLength: 2048, //can be 1024, 2048, or 4096
			publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
			hash: { name: 'SHA-256' } //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
		},
		true, //whether the key is extractable (i.e. can be used in exportKey)
		['encrypt', 'decrypt'] //can be any combination of "sign" and "verify"
	);
	let publicKey = exportPemKey(keys.publicKey);
	let privateKey = keys.privateKey;

	return { publicKey, privateKey };
};

function b642ab(base64string) {
	return Uint8Array.from(window.atob(base64string), (c) => c.charCodeAt(0));
}

export const decryptData = async (privateKey, encrypted) => {
	let decrypted = await window.crypto.subtle.decrypt(
		{
			name: 'RSA-OAEP'
		},
		privateKey,
		b642ab(encrypted)
	);
	decrypted = new TextDecoder().decode(decrypted);

	return decrypted;
};
// encrypt the token from login using library
export const encryptToken = (key, token) => {
	const encrypt = new JSEncrypt();
      encrypt.setPublicKey(key);
      const encrypted = encrypt.encrypt(token);
	return encrypted;
};
