import { defineStore } from 'pinia';

import { PINIA_PERSIST_OPTIONS } from '@/helpers';
import { updateCallNote, addNewCallNote, addNewContactNote, updateContactNote } from '@/helpers/queries';
import logger from '@/helpers/logger';
import { notify } from '@kyvg/vue3-notification';

export const useNotesStore = defineStore({
	id: 'notes',
	state: () => ({
		currentNoteCallId: null,
		unsavedNotes: [],
		isLoading: false,
		showUnsavedNotes: false
	}),
	getters: {},
	actions: {
		initNewNote(note) {
			if (!note.call_id && !note.id) {
				throw new Error('You need to specify new note with a callId or an id');
			}
			// Replace the existing one with the callId if it already exists
			this.unsavedNotes = [
				...this.unsavedNotes.filter(
					(unsavedNote) => note.id !== unsavedNote.id && note.call_id !== unsavedNote.call_id
				),
				note
			];
		},
		getNoteIndex(callId, noteId) {
			const index = this.unsavedNotes.findIndex((note) => note.call_id === callId && noteId === note.id);
			return index > -1 ? index : null;
		},
		async saveCallNote(note, idForUpdate = undefined, discardAfterSave = false) {
			note = { ...note, ...(idForUpdate ? { id: idForUpdate } : {}) };
			let noteId = idForUpdate;
			const unsavedNote = this.unsavedNotes.find((_note) => _note.call_id === note.call_id && _note.id === note.id);
			if (!unsavedNote) {
				// if call note component is not mounted,
				logger.warn(`This note with callId ${note.call_id} does not exist`);
				return;
			}
			this.isLoading = true;
			try {
				if (idForUpdate) {
					await updateCallNote(
						{
							note: unsavedNote.note
						},
						idForUpdate
					);
				} else {
					if (!unsavedNote.hasOwnProperty('note') || unsavedNote.note === '') {
						// Handle the case where unsavedNote.note is missing or empty
						return;
					}
					const createdNote = await addNewCallNote(unsavedNote, !!note.call_log_id);
					noteId = createdNote.id;
				}

				this.unsavedNotes = this.unsavedNotes.map((_note) => {
					if (_note.call_id === note.call_id) {
						_note.id = noteId;
					}
					return _note;
				});

				if (discardAfterSave) {
					this.discardNote(note.call_id, noteId);
				}
			} catch (e) {
				console.log(e, 'note store');
				logger.error(e, `Error saving note ${unsavedNote}`);
				throw e;
			} finally {
				this.isLoading = false;
			}
			return noteId;
		},

		async saveContactNote(note, idForUpdate = undefined) {
			note = { ...note, ...(idForUpdate ? { id: idForUpdate } : {}) };

			this.isLoading = true;
			try {
				if (idForUpdate) {
					const response = await updateContactNote({ note: note.note }, idForUpdate);
					return response;
				} else {
					const createdNote = await addNewContactNote(note);
					return createdNote;
				}
			} catch (e) {
				logger.error(e, `Error saving note ${note}`);
				throw e;
			} finally {
				this.isLoading = false;
			}
		},

		discardNote(callId, noteId) {
			if (callId && noteId) {
				this.unsavedNotes = this.unsavedNotes.filter((note) => note.id !== noteId && note.call_id !== callId);
			}
		},
		removeEmptyNewNote() {
			this.unsavedNotes = this.unsavedNotes.filter((note) => !!note.note);
		}
	},
	persist: PINIA_PERSIST_OPTIONS
});
