import Engage from '@engage_so/js';
import { track } from './analytics.js';
import { IS_PROD, ENGAGE_PRIVATE_KEY, ENGAGE_PUBLIC_KEY } from '@/helpers/constants.js';


export const ENGAGE_EVENTS = {
	COMPLETED_SETUP_FLOW: 'Completed Number Set Up',
	SELECTED_INVITE_TEAM_MEMBER: 'Selected Invite Team Members',
	SELECTED_PROFESSIONAL_GREETING: 'Selected Professional Greetings',
	SELECTED_ENTIRE_FLOW: 'Selected Both Setup Options',
	COMPLETED_PROFESSIONAL_GREETING: 'Completed Professional Greetings',
	COMPLETED_INVITE_TEAM_MEMBER: 'Completed Invite Team Members',
	COMPLETED_ENTIRE_FLOW: 'Completed Setup Flow',
	STARTED_WALKTHROUGH: 'Started Walkthrough',
	STOPPED_WALKTHROUGH: 'Stopped Walkthrough',
	COMPLETED_WALKTHROUGH_GUIDE: 'Completed Walkthrough Guide',
	PLAYSTORE_APP_BTN_CLICKED: 'PlayStore App Btn Clicked',
	APPSTORE_APP_BTN_CLICKED: 'Apple Store App Btn Clicked',
	ADDED_APP_TO_HOME_SCREEN: 'Added App To Home Screen Or Bookmarked',
	WILL_ADD_APP_TO_HOME_SCREEN_LATER: 'Will Add App To Home Screen Later',
	APP_TO_HOME_SCREEN_NOT_WORKING: 'App To Home Screen Not Working',
	OTP_ON_THANK_YOU_PAGE_CLICKED: 'OTP On Thank You Page Clicked',
	SELECTED_BASIC_PLAN: 'Selected Basic Plan',
	SELECTED_SOLOPRENUER_PLAN: 'Selected Solopreneur Plan',
	SELECTED_STANDARD_PLAN: 'Selected Standard Plan',
	SELECTED_GROWTH_PLAN: 'Selected Growth Plan',
	SELECTED_INTERNATIONAL_PLAN: 'Selected International Plan',
	SELECTED_PRO_PLAN: 'Selected Pro Plan',
	SELECTED_PLATINUM_PLAN: 'Selected Platinum Plan',
	CLOSED_DOWNLOAD_BANNER: 'Closed Download Banner on Home Page',
	CLOSED_RENEWAL_BANNER: 'Close Subscription Renewal Banner',
	RECOMMENDED: 'Recommended Plan',
	RATED_CALL_1: 'Rated Call Quality to be 1',
	RATED_CALL_2: 'Rated Call Quality to be 2',
	RATED_CALL_3: 'Rated Call Quality to be 3',
	RATED_CALL_4: 'Rated Call Quality to be 4',
	RATED_CALL_5: 'Rated Call Quality to be 5',
	TOGGLE_CALL_PANEL: 'Toggle call panel',
	SELECTED_DID_NOT_HEAR_ANY_VOICE: 'I did not hear anything',
	VIEWED_RETRY_FAILED_CALL_SCREEN: 'Viewed Retry Failed Call Screen',
	CALL_FAILED_TRY_AGAIN: 'Call Failed_Try Again',
	CALL_FAILED_I_WILL_DO_THIS_LATER: 'Call Failed_I Will Do This Later',
	VIEWED_RECOMMENDED_PLAN_PAGE_FROM_RETARGETING_LINK: 'Viewed Recommended Plan Page from Retargeting Link',
	CLICKED_SUBSCRIBE_BUTTON_ON_RETARGETING_LINK: 'Clicked Subscribe button on Retargeting Link',
	SUBSCRIPTION_RENEWAL: 'Subscription Renewal Button Clicked',
	SELECTED_OTHER_PROBLEMS: 'I faced Other Issues Not listed in the options',
	VIEWED_PROFESSIONAL_GREETINGS: 'Viewed Professional Greetings',
	CLICKED_COMPLETE_SETUP_ON_PRO_GREETINGS: 'Clicked CTA Button to complete Professional Greetings',
	LIKED_MY_PROFESSIONAL_GREETINGS: 'I liked my Porfessional Greetings',
	DID_NOT_LIKE_MY_PROFESSIONAL_GREETINGS: 'I did not like my professional greetings',
	REQUESTED_CUSTOM_VOICE: 'I Requested for a custom voice'
};

const _MIXPANEL_TOKEN = '2c91e98b6d0b2814042994af76445638';

const _DEBUG = false;

export function engage_track(user_id, event, properties = {}) {
	Engage.init({
		key: ENGAGE_PUBLIC_KEY,
		secret: ENGAGE_PRIVATE_KEY
	});
	// Object.assign incase there is some reactive value that could change before mixpanel sends
	Engage.track(user_id, {
		event: event,
		properties: Object.assign({}, properties)
	});
	track(event, { user: user_id });
}
export async function engage_identify(user_id, properties) {
	Engage.init({
		key: ENGAGE_PUBLIC_KEY,
		secret: ENGAGE_PRIVATE_KEY
	});
	await Engage.identify({
		id: user_id,
		...properties
	});
}
export async function engage_identify_track(user_id, event, properties) {
	Engage.init({
		key: ENGAGE_PUBLIC_KEY,
		secret: ENGAGE_PRIVATE_KEY
	});

	await Engage.identify({
		id: user_id,
		...properties
	});
	Engage.track(user_id, {
		event: event,
		properties: Object.assign({}, properties)
	});
	track(event, { user: user_id });
}

// export function identify_tracked_user(email) {
// 	if (IS_PROD) {
// 		mixpanel.init(_MIXPANEL_TOKEN, { debug: _DEBUG });
// 		mixpanel.identify(email);
// 	} else {
// 		logger.info(`[NON-PROD] Mixpanel (Identify): ${email}`);
// 	}
// }

// export function track_error(event, error) {
// 	if (IS_PROD) {
// 		mixpanel.init(_MIXPANEL_TOKEN, { debug: _DEBUG });
// 		mixpanel.track(event, { error: error });
// 	} else {
// 		logger.warn(`[NON-PROD] Mixpanel (Track error): ${event} - ${JSON.stringify(error)}`);
// 	}
// }

/**
 *
 * @param user CurrentUser object from UsersStore
 */
// export function register(user) {
// 	//first identity user
// 	if (IS_PROD) {
// 		try {
// 			identify_tracked_user(user.personal_email);
// 		} catch (e) {
// 			/*do nothing*/
// 			logger.error(`Error identifying tracked user: ${user.personal_email}`);
// 		}

// 		// build user data from User object, and register
// 		mixpanel.init(_MIXPANEL_TOKEN, { debug: _DEBUG });
// 		let user_data = {};
// 		user_data = {
// 			email: user.personal_email,
// 			phone: user.mobile,
// 			first_name: user.first_name,
// 			last_name: user.last_name
// 		};
// 		try {
// 			if (user.team_size) user_data['team_size'] = user.team_size;
// 			if (user.business_name) user_data['business_name'] = user.business_name;
// 		} catch (e) {
// 			/*do nothing*/
// 		}

// 		mixpanel.register(user_data);

// 		// Set People profile. Untested Code. Put in try block
// 		try {
// 			user_data = {
// 				$email: user.personal_email,
// 				$phone: user.mobile,
// 				$first_name: user.first_name,
// 				$last_name: user.last_name
// 			};
// 			mixpanel.people.set(user_data);
// 		} catch (e) {
// 			/*do nothing*/
// 			logger.error(e, `Mixpanel set people profile error.`);
// 		}
// 	} else {
// 		logger.log({}, 'Ignoring mixpanel init, app not running in production.');
// 	}
// }
