import Tracker from '@openreplay/tracker';
import { IS_PROD } from './constants';

const openReplay = new Tracker({
	projectKey: import.meta.env.VITE_OPEN_REPLAY_KEY,
	ingestPoint: 'https://openreplay.pressone.co/ingest',
	network: {
		capturePayload: true // Capture HTTP payload
	},
	__DISABLE_SECURE_MODE: !IS_PROD
});


export default openReplay;
