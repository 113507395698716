import { useDialerStore } from '@/stores/dialer.store.js';
import { useAuthStore } from '@/stores/auth.store';
import {
	INTERNAL_LIVE_BASE_API,
	PAYSTACK_BASE_MONTHLY,
	PAYSTACK_INT_MONTHLY,
	PAYSTACK_PRO_MONTHLY,
	PAYSTACK_TOP_UP_LINK,
	PRODUCTION_BASE_API,
	STAGING_BASE_API
} from '@/helpers/constants.js';
import logger from '@/helpers/logger';
import { useNumbersStore, useUsersStore, useUtilstStore } from '@/stores';
import moment from 'moment';
import { getUserSubscriptionStatus } from './queries';
import { notify } from '@kyvg/vue3-notification';
import geohash from 'ngeohash';
import { shake256 } from 'js-sha3';

export const validateMobile = (mobile_phone) => {
	if (mobile_phone == null) {
		return '+234';
	}
	//remove spaces from input
	mobile_phone = mobile_phone.replace(/\s+/g, '');

	//remove any non-numeric input, but leave +.
	mobile_phone = mobile_phone.replace(/[^\d+]/g, '');

	//check phone number to make sure it starts with +234
	if (mobile_phone.substring(0, 1) !== '+') {
		if (mobile_phone.substring(0, 3) === '234')
			//if input is "234803444555", then just add a +
			mobile_phone = '+' + mobile_phone;
		else if (mobile_phone.substring(0, 1) === '0')
			//else add a +234
			mobile_phone = '+234' + mobile_phone.substring(1);
		else mobile_phone = '+234' + mobile_phone;
	}
	return mobile_phone;
};

export const getSubscriptionDetailForOwnersAndAdmins = async () => {
	const numberStore = useNumbersStore();
	const userStore = useUsersStore();
	const utilsStore = useUtilstStore();
	const activeNum = numberStore.activeNumber;
	const receivers = userStore.currentUser?.receivers;
	let index = 0;
	for (index; index < receivers?.length; index++) {
		// Get the index for the current business number selected by the currentUser
		if (receivers[index]?.business_number?.phone_number === activeNum?.business_number?.phone_number) {
			break;
		}
	}
	const businessId = userStore?.currentUser.receivers[index]?.business_number.id;
	if (!businessId) {
		return;
	}
	let status;
	try {
		status = await getUserSubscriptionStatus(businessId);

		utilsStore.inactiveBusinessId = status.detail === 'active' ? null : businessId;
		utilsStore.subscriptionStatus = status.detail;
		// if (status.detail !== 'past_due') {
		// 	utilsStore.expiredSubscriptionModal = false;
		// }

		// if (
		// 	userStore?.currentUser?.receivers[index]?.receiver_role === 'owner' ||
		// 	userStore?.currentUser?.receivers[index]?.receiver_role === 'administrator'
		// ) {
		// }
	} catch (error) {
		logger.error(error, 'Error getting subscription status');
	}
	return {
		status: status?.detail,
		id: businessId
	};
};

export const formatPhoneNumber = (phone_number, contact_name = '') => {
	//Declare possible matches
	let matchMobile = phone_number.match(/^(0|)?(\d{3})(\d{3})(\d{4})$/); // match 08012878233 or 8012878233
	let matchLand = phone_number.match(/^(0|)?(\d{1})(\d{3})(\d{4})$/); //match 017743211 or 17743211
	let matchLand2 = phone_number.match(/^(\d{3})(\d{4})$/); //match 7743211
	let matchFullMobile = phone_number.match(/^(\+234|)(\d{3})(\d{3})(\d{4})$/); //match +2348012878233
	let matchFullLand = phone_number.match(/^(\+234)(\d{1})(\d{3})(\d{4})$/); //match +23417743211
	let matchFullUS = phone_number.match(/^(\+1)(\d{3})(\d{3})(\d{4})$/); //match +14083326784

	//format based on match
	if (matchFullMobile) {
		phone_number = [matchFullMobile[1], ' ', matchFullMobile[2], ' ', matchFullMobile[3], ' ', matchFullMobile[4]].join(
			''
		);
	} else if (matchMobile) {
		phone_number = ['+234', ' ', matchMobile[2], ' ', matchMobile[3], ' ', matchMobile[4]].join('');
	} else if (matchFullLand) {
		phone_number = [matchFullLand[1], ' ', matchFullLand[2], ' ', matchFullLand[3], ' ', matchFullLand[4]].join('');
	} else if (matchLand) {
		phone_number = ['+234', ' ', matchLand[2], ' ', matchLand[3], ' ', matchLand[4]].join('');
	} else if (matchLand2) {
		phone_number = ['+234 1', ' ', matchLand2[1], ' ', matchLand2[2]].join('');
	} else if (matchFullUS) {
		phone_number = [matchFullUS[1], '(', matchFullUS[2], ') ', matchFullUS[3], ' ', matchFullUS[4]].join('');
	}
	// Add contact name if included
	if (contact_name) return contact_name;

	return phone_number;
};

export const buildPaymentLink = (currentUser, planAmount) => {
	let payment_link = '';
	let extra_params = '';

	switch (planAmount) {
		case 1499:
			payment_link = PAYSTACK_BASE_MONTHLY;
			break;
		case 4999:
			payment_link = PAYSTACK_PRO_MONTHLY;
			break;
		case 9999:
			payment_link = PAYSTACK_INT_MONTHLY;
			break;
		default: //if no plan is specified, then this is a top up
			payment_link = PAYSTACK_TOP_UP_LINK;
	}
	if (currentUser) {
		extra_params = [
			'?first_name=' + currentUser.first_name,
			'last_name=' + currentUser.last_name,
			'email=' + currentUser.personal_email,
			'phone=' + currentUser.mobile
		].join('&');
	}

	return payment_link + extra_params;
};

/**
 * Determine if a user just joined PressOne and needs to complete their profile
 * @param first_name
 * @param last_name
 * @param date_joined
 * @returns {boolean}
 */
export const userJustJoined = (first_name, last_name) => {
	function _containsNumbers(str) {
		return /\d/.test(str);
	}

	if (_containsNumbers(first_name) && _containsNumbers(last_name))
		//by default PressOne sets your first name and last name to the phone number you signed up with
		return true;
	return false;
};

export const getWebdialerHost = () => {
	const currentHost = window.location.hostname;
	let webdialerHost = 'https://webdialer.pressone.co';
	if (currentHost.includes('pressone.africa')) {
		webdialerHost = 'https://dialer.pressone.africa';
	}
	return webdialerHost;
};

export const buildWebdialerLink = (mobile_number, receiver_number_id) => {
	const formattedPhone = validateMobile(mobile_number);
	const webdialerHost = getWebdialerHost();
	logger.info(receiver_number_id.toString(16));
	return `${webdialerHost}/?s=${receiver_number_id.toString(16)}.${formattedPhone.substring(1)}`;
};

export const formatResults = (arrayComingFromPeopleApi) => {
	const resources = arrayComingFromPeopleApi.map((resource) => {
		// get multiple email addresses and phone numbers if applicable
		const { emailAddresses = [], names = [], phoneNumbers = [], organizations = [] } = resource;
		const email = emailAddresses.map((email = {}) => email.value || '');
		const phone = phoneNumbers.map((phone = {}) => phone.value || '');
		const lastName = names.map((name = {}) => name.familyName || '');
		const firstName = names.map((name = {}) => name.givenName || '');
		const organization = organizations.map((name = {}) => name.name || '');

		return {
			first: firstName[0],
			last: lastName[0],
			email,
			phone,
			organization
		};
	});
	return resources;
	// commit the resources to the store
};

export const createAudioElement = (containerId) => {
	const sound = document.createElement('audio');
	sound.controls = 'controls';
	sound.style.display = 'none';
	document.getElementById(containerId).appendChild(sound);
	return sound;
};

export const secondsToCallTimer = (secondsToParse) => {
	const sec_num = parseInt(secondsToParse, 10); // don't forget the second param
	let hours = Math.floor(sec_num / 3600);
	let minutes = Math.floor((sec_num - hours * 3600) / 60);
	let seconds = sec_num - hours * 3600 - minutes * 60;

	if (hours < 10) {
		hours = '0' + hours;
	}
	if (minutes < 10) {
		minutes = '0' + minutes;
	}
	if (seconds < 10) {
		seconds = '0' + seconds;
	}
	return hours + ':' + minutes + ':' + seconds;
};

export const beginCall = (phoneNumber) => {
	const dialerStore = useDialerStore();
	dialerStore.beginCall(phoneNumber);
};

export const validateText = (e) => {
	if (!/^[\p{L} ,.'-]+$/u.test(e.key)) {
		e.preventDefault();
	}
};

export const validateNumberInput = (e) => {
	if (/^\W$/.test(e.key)) {
		e.preventDefault();
	}
};

export const formatNumber = (number) => {
	return number.toLocaleString();
};

export const switchBaseURL = async (key) => {
	switch (key) {
		case 'staging':
			localStorage.setItem('baseUrl', STAGING_BASE_API);
			break;
		case 'internal_live':
			localStorage.setItem('baseUrl', INTERNAL_LIVE_BASE_API);
			break;
		case 'production':
			localStorage.setItem('baseUrl', PRODUCTION_BASE_API);
			break;
		default:
			break;
	}
	const authStore = useAuthStore();
	if (authStore.isAuthenticated) {
		await authStore.logout();
	}
	location.reload();
};

export const timeZones = [
	'Coordinated Universal Time (UTC)',
	'Greenwich Mean Time (GMT)',
	'Eastern Standard Time (EST)',
	'Central Standard Time (CST)',
	'Mountain Standard Time (MST)',
	'Pacific Standard Time (PST)',
	'Eastern European Time (EET)',
	'Central European Time (CET)',
	'Western European Time (WET)',
	'Australian Eastern Standard Time (AEST)',
	'Australian Central Standard Time (ACST)',
	'Australian Western Standard Time (AWST)',
	'Indian Standard Time (IST)',
	'Japan Standard Time (JST)',
	'China Standard Time (CST)',
	'Central Africa Time (CAT)',
	'Brasília Standard Time (BRT)',
	'Atlantic Standard Time (AST)',
	'Alaska Standard Time (AKST)',
	'Hawaii-Aleutian Standard Time (HST)'
];

export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const areAllKeysNotEmpty = (obj) => {
	for (const key in obj) {
		if (obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === undefined || obj[key] === '')) {
			return false; // Return false if any key has an empty value
		}
	}
	return true; // If no empty values are found for any key
};

export const getYearAndMonthFromDate = (dateString) => {
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

	const dateTimeParts = dateString.split(' ');
	const dateParts = dateString.split(' ')[0].split('-');
	const timeParts = dateTimeParts[1].split(':');

	const year = parseInt(dateParts[0]);
	const monthIndex = parseInt(dateParts[1]) - 1;
	const monthName = months[monthIndex];
	const day = new Date(dateParts).toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'short',
		day: 'numeric'
	});
	const hour = parseInt(timeParts[0]);
	const minute = parseInt(timeParts[1]);

	let period = 'am';
	let formattedHour = hour;

	if (hour >= 12) {
		period = 'pm';
		formattedHour = hour === 12 ? 12 : hour - 12;
	}
	let time = `${formattedHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}${period}`;
	const monthAndYear = `${monthName} ${year}`;

	return { year, month: monthName, day, time, monthAndYear };
};

export function getDifferenceType(date1, date2) {
	const timeDifference = moment(date2).diff(moment(date1), 'milliseconds');

	if (timeDifference >= moment.duration(1, 'year').asMilliseconds()) {
		return 'yearly';
	} else if (timeDifference >= moment.duration(1, 'month').asMilliseconds()) {
		return 'monthly';
	} else if (timeDifference >= moment.duration(1, 'week').asMilliseconds()) {
		return 'weekly';
	} else {
		const dayTimeDifference = 86400000;
		if (timeDifference <= dayTimeDifference) return 'daily';
		else return 'weekly'; // set to show days on the x-axis when the time difference is greater than a day
	}
}
export function getFirstName(fullName) {
	return fullName?.split(' ')[0];
}
export function getLastName(fullName) {
	const names = fullName.split(' ');
	return names[names.length - 1];
}
export function truncateWord(word) {
	if (word.length > 30) {
		return word.substring(0, 30) + '...';
	} else {
		return word;
	}
}
export function sanitizeInput(input) {
	// Use a library for HTML escaping, or implement a simple function
	// This example uses the DOMParser to escape HTML entities
	const doc = new DOMParser().parseFromString(input, 'text/html');
	return doc.body.textContent || '';
}

export function getTimeZone() {
	// Get the current date
	const currentDate = new Date();

	// Get the UTC offset in minutes
	const utcOffsetInMinutes = currentDate.getTimezoneOffset();

	// Convert the offset to hours and minutes
	const hours = Math.floor(Math.abs(utcOffsetInMinutes) / 60);
	const minutes = Math.abs(utcOffsetInMinutes) % 60;

	// Determine the sign of the offset
	const sign = utcOffsetInMinutes < 0 ? '+' : '-';

	// Construct the UTC offset string
	const utcOffsetString = `UTC${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

	return utcOffsetString;
}

export function getLanguage() {
	const language = navigator.language || navigator.userLanguage;
	return language;
}

export function getCountryCode(mobileNumber) {
	if (mobileNumber.startsWith('+1')) {
		return 'US';
	} else if (mobileNumber.startsWith('+254')) {
		return 'KE';
	} else if (mobileNumber.startsWith('+234')) {
		return 'NG';
	} else {
		return 'NG'; // If the country code is not recognized
	}
}
function encodeGeolocation(latitude, longitude) {
	try {
		const encoded = geohash.encode(latitude, longitude);
		return encoded;
	} catch (error) {
		return '';
	}
}
export async function getGeolocation() {
	try {
		const pos = await new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(resolve, reject);
		});
		return encodeGeolocation(pos.coords.latitude, pos.coords.longitude);
	} catch (error) {
		return '';
	}
}

export function hashTimezone() {
	return shake256(Intl.DateTimeFormat().resolvedOptions().timeZone, 40);
}
