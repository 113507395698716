import amplitude from 'amplitude-js';
import { IS_PROD, IS_INTERNAL } from '@/helpers/constants.js';
import logger from '@/helpers/logger.js';

const _AMPLITUDE_API_KEY = 'd07c19eb097dad4b443472760ae7b59a';

export const AMPLITUDE_EVENTS = {
	SIGNUP: 'User Signup',
	LOGIN: 'User Signin',
	BRASS_USER_SIGNUP: 'Brass User Signed up',
	SCALEIN_USER_SIGNUP: 'ScaleIn User Signed up',
	STERLING_USER_SIGNUP: 'Sterling User Signed up',
	PRINTIVO_USER_SIGNUP: 'Printivo User Signed up',
	SIDE_BRIEF_USER_SIGNUP: 'Side Brief User Signed up',
	RECOMMENDED: 'Recommended Plan',
	SIP_CONNECTED: 'Sip Connected',
	SIP_DISCONNECTED: 'Sip Disconnected',
	SIP_CONNECTION_ERROR: 'Sip Connection Error',
	WGH_REFERALL_SIGNUP: 'New WGH referral signup',
	PRESSONE_WEBSITE_SIGNUP: 'New PressOneWebsite signup'
};

export function track_amplitude(event, prop) {
	try {
		if (IS_PROD || IS_INTERNAL) {
			amplitude.getInstance().init(_AMPLITUDE_API_KEY);
			amplitude.getInstance().logEvent(event, Object.assign({}, prop));
		} else {
			logger.info(`[NON-PROD] Amplitude (Track): ${event} - ${JSON.stringify(Object.assign({}, prop))}`);
		}
	} catch (error) {
		logger.error(error, `Could not send event to amplitude: ${event}`);
	}
}

export function set_tracked_user(email) {
	try {
		if (IS_PROD || IS_INTERNAL) {
			amplitude.getInstance().init(_AMPLITUDE_API_KEY);
			amplitude.getInstance().setUserId(email);
		} else {
			logger.info(`[NON-PROD] Amplitude (Identify):`);
		}
	} catch (error) {
		logger.error(error, `Could not Identify user amplitude`);
	}
}

export function set_user_properties(props) {
	try {
		if (IS_PROD || IS_INTERNAL) {
			amplitude.getInstance().init(_AMPLITUDE_API_KEY);
			amplitude.getInstance().setUserProperties(props);
		} else {
			logger.info(`[NON-PROD] Amplitude (Set user properties`);
		}
	} catch (error) {
		logger.error(error, `Could not Identify user amplitude`);
	}
}
