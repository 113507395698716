
var injectDialerInstance = null;
export const webDialerPlugin = {
  install(app, options = {}) {
    app.config.globalProperties.$setPressoneInstance = (data) => {
        injectDialerInstance = data;
      };
    app.config.globalProperties.$getPressoneInstance = () => injectDialerInstance
    app.provide('setPressoneInstance', app.config.globalProperties.$setPressoneInstance);
    app.provide('getPressoneInstance', app.config.globalProperties.$getPressoneInstance);
  },
};
