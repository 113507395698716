// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported } from 'firebase/messaging';

// import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
	apiKey: 'AIzaSyCnBoKrsMaYYqWcgtqccF7Ow81mIq5TJhM',
	authDomain: 'pressone.firebaseapp.com',
	projectId: 'pressone',
	storageBucket: 'pressone.appspot.com',
	messagingSenderId: '99899930178',
	appId: '1:99899930178:web:37bd719ebfe458a07f5fdb',
	measurementId: 'G-743JZ94Y1D'
};
// Initialize Firebase
export const initMessaging = () => getMessaging(initializeApp(firebaseConfig));
