// contacts-store.js

import { defineStore } from 'pinia';
import { fetchUserCalls, fetchUserContacts } from '@/helpers';
import { PINIA_PERSIST_OPTIONS } from '@/helpers';

export const useContactsStore = defineStore({
	id: 'contacts',
	state: () => ({
		contacts: [],
	}),
	getters: {
		getContacts() {
			return this.contacts;
		},
		getUnduplicatedContacts () { // removes duplicated contact names
			return this.contacts.filter((item, index) => {
				return this.contacts.indexOf(item) === index;})
		}
	},
	actions: {
		async fetchContacts() {
			const response = await fetchUserContacts();
			this.contacts = response.results;
			let nextPage = response.next;
			while (nextPage) {
				const nextPageResponse = await fetchUserCalls(nextPage);
				const { results, next } = nextPageResponse;
				this.contacts = [...this.contacts, ...results];
				if (next === null) {
					break;
				}
				nextPage = next;
			}
		},
	},
	persist: PINIA_PERSIST_OPTIONS
});
