import { defineStore } from 'pinia';
import { PINIA_PERSIST_OPTIONS } from '@/helpers';
import logger from '@/helpers/logger';

export const useUtilstStore = defineStore({
	id: 'utils',
	state: () => ({
		announcement: true,
		existingBusiness: null,
		newBusinessObject: null,
		pendingSetupPayload: null,
		numberPurchaseMode: false,
		welcomeGreetingsRecentlySet: false,
		IVRRecentlySet: false,
		showVerificationBanner: true,
		showVerificationModal: false,
		nin: '',
		showVerificationNotification: false,
		setUpProgressTracker: {
			setUpMode: 'default',
			currentStep: 1,
			isActiveStep: false,
			inviteMode: null,
			completedSteps: [],
			isProcessComplete: true,
			isProcessStarted: false
		},
		setUpSteps: [
			{
				name: 'Invite Your Team Members to PressOne',
				completed: false
			},
			{
				name: 'Configure Incoming Call Flow',
				completed: false
			},
			{
				name: 'Setup Professional Greetings',
				completed: false
			},
			{
				name: 'Publish Your PressOne Number',
				completed: false
			}
		],
		onboardingStartModal: false,
		walkThroughGuide: {
			start: false,
			done: false,
			currentStep: null
		},
		subscriptionPlans: null,
		isRecommendedPlanView: false,
		isRecommendedPlanMode: false,
		nonRecommendedPlanId: null,
		signUpMode: false,
		signUpFormData: {
			first_name: '',
			last_name: '',
			personal_email: '',
			mobile: '',
			id: null
		},
		signUpSource: '',
		affiliateLinkRef: '',
		affiliateLinkId: '',
		afffiliateLinkCode: '',
		affiliateData: null,
		downloadMobileAppMode: false,
		showTestCall: false,
		// Checks if user has completed or ignored the testcall in complete-setup view
		hasTestedCall: false,
		showTestProGreetings: false,
		hasListenedToGreeting: false, // Checks if user has listened to the testgreeting
		businessFormData: null,
		isDoneOnboarding: true, // Checks if user has completed all the setup flow
		selectProblemWithProGreeting: false, // Shows modal if there was an issue with the greeting
		resetGreetingVoice: false, // Shows screen for user complaint to change voice
		resetGreetingText: false, // Shows screen for user complaint to edit greeting script
		showResetStartModal: false,
		showDownloadBanner: true,
		showAddAppToHomeScreenModal: false,
		showAddAppToHomeScreenlater: false,
		appAddedToHomeScreen: false,
		showRenewalBanner: false,
		transactionRef: null,
		inactiveBusinessId: null,
		hasRenewedExpiredSubscription: true,
		isNumberProvisioning: false,
		isNumberActivating: false,
		pendingMember: null,
		hasUpgradedTeam: false,
		isCallFromTest: false,
		showBrassPopUpModal: null,
		isNewBusinessNumberSetup: false,
		isTicketRaised: false,
		ticketSupportPage: null,
		showDownloadModalForMobile: false,
		finishedProGreetingsForMobile: false,
		selectedIVRVoice: null,
		expiredSubscriptionModal: false,
		showSubRestrictionModal: false,
		subscriptionStatus: null,
		oldPlans: [
			{
				id: 1,
				title: 'PressOne Basic',
				standard_cost_currency: 'NGN',
				standard_cost: '100.00',
				number_region: 'NG',
				term: 'monthly'
			},
			{
				id: 2,
				title: 'PressOne Pro Business',
				standard_cost_currency: 'NGN',
				standard_cost: '100.00',
				number_region: 'NG',
				term: 'monthly'
			},
			{
				id: 3,
				title: 'PressOne Platinum',
				standard_cost_currency: 'NGN',
				standard_cost: '100.00',
				number_region: 'NG',
				term: 'monthly'
			}
		]
	}),
	getters: {
		isSetUpProcessComplete: (state) => !!state.setUpProgressTracker.isProcessComplete
	},
	actions: {
		setExistingBusinessPayload(payload) {
			this.existingBusiness = payload;
		},
		closeVerificationBanner() {
			this.showVerificationBanner = false;
		},
		openVerificationModal() {
			this.showVerificationModal = true;
		},
		closeVerificationNotification() {
			this.showVerificationNotification = false;
		},
		checkIfDesktop() {
			let isDesktop = false;
			if (
				!navigator.userAgent.match(
					/Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi|Tablet|iPad/i
				)
			) {
				isDesktop = true;
			}
			return isDesktop;
		},
		setShowAddAppToHomeScreenModal(payload) {
			this.showAddAppToHomeScreenModal = payload;
		},
		setShowAddAppToHomeScreenlater(payload) {
			this.showAddAppToHomeScreenlater = payload;
		},
		setAppAddedToHomeScreen(payload) {
			this.appAddedToHomeScreen = payload;
		},
		setSubscriptionPlans(payload) {
			const idToModelMap = [
				'As a Solopreneur, this plan allows you to make, and receive calls. You can create professional greetings for your business. You can have only 1 user in this plan.',
				'With PressOne Standard plan, you can make 5 calls at the same time. You can add up to five users. After the first 2,  an extra user incurs a charge of N2,000. You also have access to Integrations and the ability to set one ring group.',
				'With PressOne Growth plan, you can access all Standard plan features with call widget, and the ability to create multiple ring groups.',
				'With PressOne International plan, you can access all Growth plan features and get access to local and international phone numbers.'
			];
			try {
				payload.sort((a, b) => a.id - b.id);
				payload.forEach((plan, index) => {
					const model = idToModelMap[index];
					if (model) {
						plan.description = model;
					}
				});
				this.subscriptionPlans = payload;
			} catch (error) {
				logger.error(error, 'error setting subscription plans');
			}
		},

		setNewBusinessPayload(payload) {
			this.newBusinessObject = payload;
		},

		setSetUpPayload(payload) {
			this.pendingSetupPayload = payload;
		},

		clearExistingBusinessPayload() {
			// this.existingBusinessId = null;
			this.existingBusiness = null;
		},

		clearNewBusinessPayload() {
			this.newBusinessObject = null;
		},
		stopWalkThroughGuide() {
			this.walkThroughGuide.done = false;
			this.walkThroughGuide.start = false;
			this.walkThroughGuide.currentStep = null;
			this.downloadMobileAppMode = true;
			if (this.checkIfDesktop()) {
				setTimeout(() => {
					this.setShowAddAppToHomeScreenModal(true);
				}, 2000);
			}
		}
	},
	persist: PINIA_PERSIST_OPTIONS
});
