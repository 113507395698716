// let baseApi = import.meta.env.VITE_BASE_API;
export const BASE_API = localStorage.getItem('baseUrl') || import.meta.env.VITE_BASE_API;
// localStorage.getItem('baseUrl') || import.meta.env.VITE_BASE_API
export const TELEPHONY_BASE_API = import.meta.env.VITE_TELEPHONY_BASE_API;
export const IS_PROD = import.meta.env.MODE === 'production';
export const APP_VERSION = import.meta.env.VITE_APP_VERSION;
export const STAGING_BASE_API = 'https://pressone-test-af3894dd-b3a0457d0941.herokuapp.com';
export const INTERNAL_LIVE_BASE_API = 'https://pressone-internal-live.herokuapp.com';
export const PRODUCTION_BASE_API = 'https://api.pressone.co';

export const IS_STAGING = BASE_API === STAGING_BASE_API;
export const IS_INTERNAL = BASE_API === INTERNAL_LIVE_BASE_API;

export const ENV_MODE = import.meta.env.MODE;
export const ENVIRONMENT_OPTIONS = [
	{ label: 'Staging', key: 'staging', value: STAGING_BASE_API },
	{ label: 'Internal Live', key: 'internal_live', value: INTERNAL_LIVE_BASE_API },
	{ label: 'Production', key: 'production', value: PRODUCTION_BASE_API }
];

export const ENGAGE_PUBLIC_KEY = import.meta.env.VITE_ENGAGE_PUBLICKEY;
export const ENGAGE_PRIVATE_KEY = import.meta.env.VITE_ENGAGE_PRIVATEKEY;

export const PAYSTACK_BASE_MONTHLY = import.meta.env.VITE_PAYSTACK_BASE;
export const PAYSTACK_PRO_MONTHLY = import.meta.env.VITE_PAYSTACK_PRO;
export const PAYSTACK_INT_MONTHLY = import.meta.env.VITE_PAYSTACK_INT;
export const PAYSTACK_TOP_UP_LINK = import.meta.env.VITE_TOP_UP_PAYMENT_LINK;

export const AUTH_EXCLUDE_LIST = [
	'/api/login/',
	'/api/auth/token/',
	'/api/auth/token/refresh/',
	'/api/subscription_plans/',
	'/api/v1/recommendation/',
	'/api/users/register/'
];

export const PINIA_PERSIST_OPTIONS = {
	storage: localStorage
};
