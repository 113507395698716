// airtable.js

import { useDialerStore } from '@/stores';
import axios from 'axios';

const AIRTABLE_BASE_ID = 'app3WT6KjaX3EcJeL/tblduUVZ98dmAFdJU'; //outbound
const INBOUND_BASE_ID = 'app3WT6KjaX3EcJeL/tblZcRBw1ePFCD6Lm'; //inbound
const BASE_ID = 'app3WT6KjaX3EcJeL';

const token = 'pat0gn1aqzP13Ifmv.7b85c62264dd4e90d510e529ab3e138324794362a7f2b1341b7aedc8e56d7c0a';

const instance = axios.create({
	baseURL: `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}`,
	headers: {
		Authorization: `Bearer ${token}`
	}
});

const instanceInbound = axios.create({
	baseURL: `https://api.airtable.com/v0/${INBOUND_BASE_ID}`,
	headers: {
		Authorization: `Bearer ${token}`
	}
});

const d = new Date();
const key = `${d.getFullYear()}_${d.getMonth() + 1}_${d.getDate()}`;
const date = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate()}`;

export const airtableService = {
	async getRecords() {
		try {
			const response = await instance.get(`?filterByFormula=key%3D"${key}"`);
			return response.data.records;
		} catch (error) {
			// throw error;
		}
	},
	async updateRecord(type) {
		const call_record = await airtableService.getRecords();
		if (call_record && call_record.length === 0) {
			await this.createRecord(type);
			return;
		}
		try {
			if (!call_record.length) return;
			let record = call_record[0];
			let RECORD_ID = record.id;
			switch (type) {
				case 'successful':
					record.fields.answered_calls = record.fields.answered_calls + 1;
					record.fields.successful_calls = record.fields.successful_calls + 1;
					break;
				case 'failed':
					record.fields.failed_calls = record.fields.failed_calls + 1;
					break;
				case 'unanswered':
					record.fields.unanswered_calls = record.fields.unanswered_calls + 1;
					break;
				case 'answered':
					record.fields.answered_calls = record.fields.answered_calls + 1;
					break;
				default:
					break;
			}
			record.fields.attempted_calls = record.fields.attempted_calls + 1;
			if (record.fields.successful_calls > 0) {
				record.fields.success_rate = (record.fields.successful_calls / record.fields.attempted_calls) * 100;
			}
			let updatedData = {
				attempted_calls: record.fields.attempted_calls,
				successful_calls: record.fields.successful_calls,
				failed_calls: record.fields.failed_calls,
				answered_calls: record.fields.answered_calls,
				unanswered_calls: record.fields.unanswered_calls,
				success_rate: record.fields.success_rate
			};
			const response = await instance.patch(`/${RECORD_ID}`, { fields: updatedData });
			if (response.status === 200) {
				await this.getRecords();
			}
		} catch (error) {
			// throw error;
		}
	},
	async createRecord(type) {
		try {
			let record = { fields: {} };
			switch (type) {
				case 'successful':
					record.fields.answered_calls = 1;
					record.fields.successful_calls = 1;
					record.fields.unanswered_calls = 0;
					record.fields.failed_calls = 0;
					record.fields.success_rate = (record.fields.successful_calls / record.fields.attempted_calls) * 100;
					break;
				case 'failed':
					record.fields.failed_calls = 1;
					record.fields.successful_calls = 0;
					record.fields.unanswered_calls = 0;
					record.fields.answered_calls = 0;
					record.fields.success_rate = 0;
					break;
				case 'unanswered':
					record.fields.unanswered_calls = 1;
					record.fields.answered_calls = 0;
					record.fields.failed_calls = 0;
					record.fields.successful_calls = 0;
					record.fields.success_rate = 0;
					break;
				case 'answered':
					record.fields.answered_calls = 1;
					record.fields.unanswered_calls = 0;
					record.fields.failed_calls = 0;
					record.fields.successful_calls = 0;
					record.fields.success_rate = 0;
					break;
				default:
					break;
			}
			let updatedData = {
				fields: {
					attempted_calls: 1,
					successful_calls: record.fields.successful_calls,
					failed_calls: record.fields.failed_calls,
					answered_calls: record.fields.answered_calls,
					unanswered_calls: record.fields.unanswered_calls,
					success_rate: record.fields.success_rate,
					date,
					key
				}
			};
			const response = await instance.post(``, updatedData);
			if (response.status === 200) {
				await this.getRecords();
			}
		} catch (error) {
			// throw error;
		}
	}
};

export const airtableInboundCallService = {
	async getRecords() {
		try {
			const response = await instanceInbound.get(`?filterByFormula=key%3D"${key}"`);
			return response.data.records;
		} catch (error) {
			// throw error;
		}
	},
	async updateRecord(type) {
		const call_record = await airtableInboundCallService.getRecords();
		if (call_record && call_record.length === 0) {
			await this.createRecord(type);
			return;
		}
		try {
			let record = call_record[0];
			let RECORD_ID = record.id;
			const { in_bound, answered, rejected, un_answered, successful, from_notification, date, key } = record.fields;
			let payload = {
				in_bound,
				answered,
				rejected,
				un_answered,
				successful,
				from_notification,
				date,
				key
			};
			switch (type) {
				case 'successful':
					payload = {
						...payload,
						// answered: payload.answered + 1,
						successful: payload.successful + 1
					};
					break;
				case 'rejected':
					payload = {
						...payload,
						rejected: payload.rejected + 1
					};
					break;
				case 'unanswered':
					payload = {
						...payload,
						un_answered: payload.un_answered + 1
					};
					break;
				case 'answered':
					payload = {
						...payload,
						answered: payload.answered + 1
					};
					break;
				default:
					break;
			}
			const dialerStore = useDialerStore();
			payload.in_bound = payload.in_bound + 1;
			if (dialerStore.callStatus.isCallActive) {
				payload.from_notification = payload.from_notification + 1;
			}
			const response = await instanceInbound.patch(`/${RECORD_ID}`, { fields: payload });
		} catch (error) {
			throw error;
		}
	},
	async createRecord(type) {
		try {
			let record = {
				fields: {
					in_bound: 1,
					answered: 0,
					rejected: 0,
					un_answered: 0,
					successful: 0,
					from_notification: 0,
					date,
					key
				}
			};
			const dialerStore = useDialerStore();
			switch (type) {
				case 'successful':
					record.fields = {
						...record.fields,
						answered: 1,
						successful: 1
					};
					break;
				case 'rejected':
					record.fields = {
						...record.fields,
						rejected: 1
					};
					break;
				case 'unanswered':
					record.fields = {
						...record.fields,
						un_answered: 1
					};
					break;
				case 'answered':
					record.fields = {
						...record.fields,
						answered: 1
					};
					break;
				default:
					break;
			}
			if (dialerStore.callStatus.isCallActive) {
				payload.from_notification = 1;
			}
			const response = await instanceInbound.post(``, record);
			// if (response.status === 200) {
			// 	await this.getRecords();
			// }
		} catch (error) {
			// throw error;
		}
	}
};

export const airtableVerificationService = {
	token: token,
	baseId: BASE_ID,
	tableName: 'Verification requests'
};
export const airtableMigrationService = {
	token: token,
	baseId: BASE_ID,
	tableName: '0201 Requests'
}
