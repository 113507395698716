<template>
	<Notifications />
	<Alert></Alert>
	<RouterView :key="route.fullPath"></RouterView>
	<!--start back-to-top-->
	<button @click="topFunction" class="btn btn-danger btn-icon" ref="mybutton" id="back-to-top">
		<i class="ri-arrow-up-line"></i>
	</button>

	<div v-if="!IS_PROD && !isAuthenticated" @click.prevent="toggleCard" class="setting">
		<div class="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2">
			<i class="mdi mdi-cog-outline fs-22"></i>
		</div>
	</div>
	<div class="card" v-show="showCard">
		<div class="card-body">
			<template v-for="(option, index) in options" :key="index">
				<div class="form-check form-check-inline">
					<label class="form-check-label">
						<input
							class="form-check-input"
							@click="switchBaseURL(option.key)"
							:checked="selectedBaseUrl === option.value"
							type="radio"
							name="env"
							id=""
							value="checkedValue"
						/>
						{{ option.label }}
					</label>
				</div>
				<br />
			</template>
		</div>
	</div>
	<!-- <div class="env-indicator" v-if="!IS_PROD">Running in Non-Production ({{ BASE_API }}) Mode</div> -->
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch, onBeforeMount, onErrorCaptured, inject } from 'vue';
import { RouterView } from 'vue-router';
import Alert from '@/components/Alert.vue';
import { useRoute } from 'vue-router';
import { InitAppJS } from './assets/js/app.js';
import logger from '@/helpers/logger.js';
import { IS_PROD, BASE_API, ENVIRONMENT_OPTIONS } from '@/helpers/index.js';
import { switchBaseURL } from './helpers/utils';
import {
	useAuthStore,
	useDialerStore,
	useNumbersStore,
	useUsersStore,
	useUtilstStore,
	useNewDialerStore
} from './stores';
import { publicPages } from '@/router/index.js';
import { getUserDetailsByToken, updateUserSession } from '@/helpers/index.js';
import { appExceptionHandler } from '@/exceptions.js';

const route = useRoute();
const dialerStore = useDialerStore();
const newDialerStore = useNewDialerStore();
const usersStore = useUsersStore();
const numberStore = useNumbersStore();
const utilsStore = useUtilstStore();
const { isAuthenticated } = useAuthStore();
const showCard = ref(false);
const setPressoneInstance = inject('setPressoneInstance');

const mybutton = ref();
const selectedBaseUrl = ref(BASE_API);
const scrollFunction = () => {
	100 < document.body.scrollTop || 100 < document.documentElement.scrollTop
		? (mybutton.value.style.display = 'block')
		: (mybutton.value.style.display = 'none');
};
const topFunction = () => {
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
};
const options = ref(ENVIRONMENT_OPTIONS);

const verifyUserSession = ref(null);

const toggleCard = () => {
	showCard.value = !showCard.value;
};

window.onscroll = () => scrollFunction();

watch(
	() => route.path,
	(newPath) => {
		if (!publicPages.includes(newPath)) {
			try {
				InitAppJS();
			} catch (e) {
				logger.warn(e, 'Error initializing UI lib app.js');
			}
		}
	}
);
// New Dialer

onBeforeMount(async () => {
	if (isAuthenticated) {
		await updateUserSession();
		verifyUserSession.value = setInterval(async () => {
			try {
				await updateUserSession();
			} catch (err) {
				//
			}
		}, 300000);
	}
});
onMounted(async () => {
	// utilsStore.showVerificationNotification = true;
	try {
		if (!publicPages.includes(route.path)) {
			InitAppJS();
		}

		if (isAuthenticated) {
			usersStore.currentUser = await getUserDetailsByToken();
		}
	} catch (e) {
		logger.warn(e, 'Error initializing UI lib app.js');
	}
	if (numberStore.numbers.length !== 0) {
		await dialerStore.initDialer();
		setPressoneInstance(await newDialerStore.connectSipToPhone());

	}

	//New Dialer

	
	// New Dialer
});

onBeforeUnmount(() => {
	clearInterval(verifyUserSession.value);
});

onErrorCaptured(appExceptionHandler);
</script>

<style scoped>
#back-to-top {
	bottom: 50px;
}

@media (max-width: 600px) {
	#back-to-top {
		bottom: 100px;
		right: 20px;
	}
}

.env-indicator {
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-size: 8px;
	letter-spacing: 2px;
	position: fixed;
	padding: 8px 32px;
	background: forestgreen;
	color: white;
	left: -53px;
	bottom: 68px;
	z-index: 99999;
	transform: rotate(35deg);
}

.setting,
.card {
	position: fixed;
	bottom: 40px;
	left: 20px;
	z-index: 1000;
}

.card {
	position: fixed;
	bottom: 80px;
	left: 20px;
	z-index: 1000;
}

.card-content {
	margin-top: 5px;
}

.card-content h3 {
	font-size: 16px;
	margin-bottom: 10px;
}

.card-content ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.card-content ul li {
	cursor: pointer;
	padding: 5px 0;
}

.card-content ul li:hover {
	background-color: #f5f5f5;
}
</style>
