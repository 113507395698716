import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import Notifications, { notify } from '@kyvg/vue3-notification';
import VueApexCharts from 'vue3-apexcharts';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import App from './App.vue';
import { router } from '@/router';
import './assets/css/bootstrap.min.css';
import './assets/css/app.min.css';
import './assets/css/custom.css';
import './assets/css/text-styles.css';
import './assets/css/style.css';
import './assets/js/layout.js';
import './assets/js/plugins.js';
import { IS_PROD, IS_STAGING, IS_INTERNAL } from '@/helpers/index.js';
import { appExceptionHandler } from '@/exceptions.js';
import logger from '@/helpers/logger';
import {webDialerPlugin} from './helpers/sharedPressoneInstance';

const app = createApp(App);

app.config.errorHandler = appExceptionHandler;
window.onerror = (message, source, lineno, colno, error) => {
	appExceptionHandler(error, null, message);
};
// window.onunhandledrejection = (event) => {
// 	event.preventDefault();
// 	appExceptionHandler(event.reason, null, event.reason.message);
// };

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(router);
app.use(Notifications);
app.use(webDialerPlugin)
app.use(VueApexCharts);
if (IS_PROD || IS_INTERNAL || IS_STAGING) {
	try {
		Sentry.init({
			app,
			dsn: `https://02725da36f4b46fca348ac6db569403b@o1125524.ingest.sentry.io/4504681683484672`,
			integrations: [
				new BrowserTracing({
					routingInstrumentation: Sentry.vueRouterInstrumentation(router),
					tracePropagationTargets: [import.meta.env.APP_HOST, /^\//]
				})
			],
			tracesSampleRate: 1.0,
			ignoreErrors: [
				'Unhandled Promise Rejection: Connect aborted.',
				'Unhandled Promise Rejection: Disconnect aborted.',
				'Unhandled Promise Rejection: WebSocket closed wss://fs1.pressone.co:7443 (code: 1006)',
				'Uncaught Error: Registerer terminated. Unable to register.',
				'Unhandled Promise Rejection: Peer connection undefined.',
				'Registerer terminated. Unable to register.',
				'Connect aborted',
				'WebSocket closed wss://fs1.pressone.co:7443 (code: 1006)',
				'Unhandled Promise Rejection: REGISTER request already in progress, waiting for final response',
				'REGISTER request already in progress, waiting for final response',
				'Unhandled Promise Rejection: Invalid state transition from Stopped to Stopped'
			]
		});
	} catch (e) {
		logger.error(e, 'Sentry init error');
		// console.dir(e)
	}
}

app.mount('#app');
