const phoneIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40" fill="none">
<path d="M39.9999 19.9975C39.9988 14.6935 37.8913 9.60703 34.1408 5.85653C30.3903 2.10603 25.3039 -0.00144196 19.9999 -0.00250244V3.9975C23.1641 3.99822 26.257 4.93677 28.8881 6.69457C31.5191 8.45237 33.57 10.9506 34.7819 13.8735C35.5861 15.815 36 17.896 35.9999 19.9975H39.9999ZM-0.00012207 15.9975V5.9975C-0.00012207 5.46706 0.210591 4.95836 0.585664 4.58328C0.960737 4.20821 1.46944 3.9975 1.99988 3.9975H11.9999C12.5303 3.9975 13.039 4.20821 13.4141 4.58328C13.7892 4.95836 13.9999 5.46706 13.9999 5.9975V13.9975C13.9999 14.5279 13.7892 15.0366 13.4141 15.4117C13.039 15.7868 12.5303 15.9975 11.9999 15.9975H7.99988C7.99988 20.241 9.68559 24.3106 12.6862 27.3112C15.6867 30.3118 19.7564 31.9975 23.9999 31.9975V27.9975C23.9999 27.4671 24.2106 26.9584 24.5857 26.5833C24.9607 26.2082 25.4694 25.9975 25.9999 25.9975H33.9999C34.5303 25.9975 35.039 26.2082 35.4141 26.5833C35.7892 26.9584 35.9999 27.4671 35.9999 27.9975V37.9975C35.9999 38.5279 35.7892 39.0366 35.4141 39.4117C35.039 39.7868 34.5303 39.9975 33.9999 39.9975H23.9999C10.7459 39.9975 -0.00012207 29.2515 -0.00012207 15.9975Z" fill="white"/>
</svg>`;

const iconBlue__01 = "#0E99D2";
const iconBlue__02 = "#006791";
const iconWhite__01 = "#ffffff";
const iconBlack__01 = "#000000";

const bottom = '40px';
const right = '20px'

let tooltipMessage = "Call us now, it's free!";

const PressOne = {
    functions: {},
    init: function(s, f) {
        let options = {}
        if (f && typeof f === "object") {
            const {
                onCallInitiated,
                onCallStarted,
                onCallEnded,
                onLogout,
                ..._options
            } = f || {};

            if (onCallInitiated) {
                this.functions.onCallInitiated = encodeURI(onCallInitiated.toString())
            }
            if (onCallStarted) {
                this.functions.onCallStarted = encodeURI(onCallStarted.toString())
            }
            if (onCallEnded) {
                this.functions.onCallEnded = encodeURI(onCallEnded.toString())
            }
            if (onLogout) {
                this.functions.onLogout = encodeURI(onLogout.toString())
            }
            options = _options
        }

        document.onload = this.load(s, options)
    },
    load: function(k, o) {
        const {
            display: d, 
            bottom: b, 
            right: r, 
            backgroundColor: bg, 
            textColor: tc, 
            backgroundColorHover: bgh, 
            textColorHover: tch,
            element: elem
        } = o;

        let bgColor = bg || iconBlue__01;
        let txtColor = tc || iconBlue__02;
        let bgHColor = bgh || iconBlack__01;
        let txtHColor = tch || iconBlack__01;
        let botm = b || bottom;
        let rit = r || right;

        const container = document.createElement('div');
        container.style.position = "fixed";
        container.style.height = "100vh";
        container.style.bottom = botm;
        container.style.right = rit;
        container.style.zIndex = 10000

        const widgetIcon = document.createElement("div")
        widgetIcon.style.display = d ? "none" : "flex";
        widgetIcon.style.flexDirection = "row";
        widgetIcon.style.position = "fixed";
        widgetIcon.style.bottom = botm;
        widgetIcon.style.right = rit;
        widgetIcon.style.opacity = d ? 0.5 : 1;
        widgetIcon.style.transition = "0.5s linear all";
        widgetIcon.onmouseenter = function() {
            minimized.style.backgroundColor = bgHColor;
            tooltip.style.border = `solid 1px ${bgHColor}`
            tooltip.style.color = txtHColor;
            closeToolTip.style.background = txtHColor;
        }
        widgetIcon.onmouseleave = function() {
            minimized.style.backgroundColor = bgColor;
            tooltip.style.border = `solid 1px ${bgColor}`;
            tooltip.style.color = txtColor;
            closeToolTip.style.background = txtColor;
        }
        
        const minimized = document.createElement("div")
        minimized.style.padding = "0px";
        minimized.style.borderRadius = "50%";
        minimized.innerHTML = phoneIcon;
        // minimized.style.fontSize = "45px";
        minimized.style.backgroundColor = bgColor;
        minimized.style.color = iconWhite__01;
        minimized.style.cursor = "pointer";
        minimized.style.width = "60px";
        minimized.style.height = "60px";
        minimized.style.textAlign = "center";
        minimized.style.justifyContent = "center";
        minimized.style.alignItems = "center";
        minimized.style.display = "flex";
        minimized.style.boxShadow = "0px 0px 5px #999";
        minimized.style.transition = "0.5s linear all"
        minimized.onclick = function() {
            widgetFrame.style.display = "flex";
            setTimeout(function() {
                widgetFrame.style.opacity = 1;
            }, 100);
            widgetIcon.style.display = "none";
            widgetIcon.style.opacity = 0.5

            const payloads = {
                ...window.PressOne.functions,
                auth: k,
                maximized: true
            }
            iframe.contentWindow.postMessage(JSON.parse(JSON.stringify(payloads)), "*")
        }
        
        const tooltip = document.createElement("div")
        tooltip.style.padding = "5px 10px";
        tooltip.style.borderRadius = "7px";
        tooltip.style.color = txtColor;
        tooltip.style.backgroundColor = iconWhite__01;
        tooltip.style.border = `solid 1px ${bgColor}`
        tooltip.style.height = "min-content";
        tooltip.style.borderRadius = "7px";
        tooltip.style.display = "flex"
        tooltip.innerHTML = tooltipMessage;
        tooltip.style.marginRight = "10px"

        const closeToolTip = document.createElement("div")
        closeToolTip.innerHTML = "×";
        closeToolTip.style.borderRadius = "50%";
        closeToolTip.style.position = "absolute";
        closeToolTip.style.background = txtColor;
        closeToolTip.style.color = iconWhite__01;
        closeToolTip.style.width = "20px";
        closeToolTip.style.height = "20px";
        closeToolTip.style.top = "-12px";
        closeToolTip.style.left = "-12px";
        closeToolTip.style.justifyContent = "center";
        closeToolTip.style.alignItems = "center";
        closeToolTip.style.display = "flex";
        closeToolTip.style.zIndex = 10001
        closeToolTip.style.cursor = "pointer"

        closeToolTip.onclick = function() {
            tooltip.style.display = "none"
        }
        
        tooltip.appendChild(closeToolTip)
        widgetIcon.appendChild(tooltip)
        widgetIcon.appendChild(minimized)
        
        const close = document.createElement("div")
        // close.style.marginLeft = "10px";
        close.style.borderRadius = "50%";
        close.innerHTML = "×";
        close.style.fontSize = "15px";
        close.style.position = "absolute";
        close.style.top = "18px";
        close.style.right = "13px";
        // close.style.display = d ? "block" : "none"
        close.style.cursor = "pointer"
        close.style.color = "black"
        
        close.style.width = "17px"
        close.style.height = "17px"
        close.style.alignItems = "center"
        close.style.justifyContent = "center"
        close.style.display = "flex"
        close.style.backgroundColor = "#ddd"
        close.onmouseenter = function() {
            close.style.color = "red"
        }
        close.onmouseleave = function() {
            close.style.color = "black"
        }
        close.onclick = function() {
            widgetFrame.style.display = "none";
            widgetFrame.style.opacity = 0.5;
            widgetIcon.style.display = "flex";
            setTimeout(function() {
                widgetIcon.style.opacity = 1;
            }, 100);
        }

        const widgetFrame = document.createElement("div");
        widgetFrame.style.display = d ? "block" : "none"
        widgetFrame.style.position = "absolute";
        widgetFrame.style.bottom = 0;
        widgetFrame.style.right = 0;
        widgetFrame.style.transition = "1s ease all";
        widgetFrame.style.opacity = d ? 1 : 0.5;
        widgetFrame.style.boxShadow = "0px 0px 25px #999";
        widgetFrame.style.borderRadius = "14px";
        widgetFrame.style.overflow = "hidden";
        
        const iframe = document.createElement('iframe');
        iframe.src = 'https://js-script-staging-pressone-crm.netlify.app/';
        iframe.height = '460px';
        iframe.width = '320px';
        iframe.frameBorder = "0"
        iframe.allowframing = true
        // iframe.sandbox = "allow-scripts"
        iframe.allow = "geolocation; microphone; camera; midi; encrypted-media"
        iframe.scroll = "no"
        iframe.style.border = "0"
        iframe.id = "pressone-iframe"
        iframe.style.backgroundColor = "none";
        iframe.allowTransparency="true";
        iframe.style.transition = "0.5s ease all";
       
        const root = elem ? document.querySelector(elem) : document.body;

        widgetFrame.appendChild(close);
        widgetFrame.appendChild(iframe);

        container.appendChild(widgetIcon);
        container.appendChild(widgetFrame);
        root.appendChild(container);
        
        iframe.onload = function() {
            const payloads = {
                ...window.PressOne.functions,
                auth: k
            }

            iframe.contentWindow.postMessage(JSON.parse(JSON.stringify(payloads)), "*")
        }

        window.addEventListener('message', function(event) {
            const { data } = event;
            const { 
                size, 
                tool_tip_message, 
                bottom_position, 
                right_position,
                primary_bg_color,
                secondary_bg_color,
                secondary_text_color,
                primary_text_color 
            } = data || {}
            if ( size === "minimize") {
                iframe.height = '460px';
                iframe.width = '320px';
            } else {
                iframe.height = '170px';
                iframe.width = '204px';
            }

            if ( tool_tip_message ) {
                tooltipMessage = tool_tip_message
            }

            if ( !b && bottom_position ) {
                container.style.bottom = `${bottom_position}px`;
                widgetIcon.style.bottom = `${bottom_position}px`;
            }

            if ( !r && right_position ) {
                widgetIcon.style.right = `${right_position}px`;
                container.style.right = `${right_position}px`;
            }
        });
        
    },
    on: function(event, f){
        const supportedEvents = ['call-initiated', 'call-started', 'call-ended'];
        if ( !supportedEvents.includes(event) ) {
            throw new Error("Unsupported event")
        }
        switch (event) {
            case 'call-initiated':
                this.onCallInitiated(f);
                break;
            case 'call-started':
                this.onCallStarted(f);
                break;
            case 'call-ended':
                this.onCallEnded(f);
                break;
        }
        return this;
    },
    onCallInitiated: function(f) {
        this.functions.onCallInitiated = encodeURI(f.toString());
        return this;
    },
    onCallStarted: function(f) {
        this.functions.onCallStarted = encodeURI(f.toString());
        return this;
    },
    onCallEnded: function(f) {
        this.functions.onCallEnded = encodeURI(f.toString());
        return this;
    },
    // onLogout: function(f) {
    //     this.functions.onLogout = encodeURI(f.toString());
    //     return this;
    // }
}

window.PressOne = PressOne;