import * as Sentry from '@sentry/vue';

const errorToSentrySeverity = {
	warn: 'warning',
	info: 'info',
	error: 'error',
	debug: 'debug'
};
const exemptErrors = [
	'Unhandled Promise Rejection: Disconnect aborted.',
	'Unhandled Promise Rejection: WebSocket closed wss://fs1.pressone.co:7443 (code: 1006)',
	'Uncaught Error: Registerer terminated. Unable to register.'
];
const log = (type, obj, message) => {
	const logFunction = console[type];
	const logArgs = [...(message ? [message] : []), ...(obj ? [obj] : [])];
	logFunction(...logArgs);

	if (type === 'error') {
		// Sentry.captureMessage(message ?? JSON.stringify(obj), errorToSentrySeverity[type]);
		if (exemptErrors.includes(message)) {
			log('warn', obj, message);
			return;
		}
		Sentry.captureException(obj, errorToSentrySeverity[type]);
	}
};

export default {
	log: (obj, message) => log('log', obj, message),
	info: (obj, message) => log('log', obj, message),
	error: (obj, message) => log('error', obj, message),
	warn: (obj, message) => log('warn', obj, message),
	debug: (obj, message) => log('debug', obj, message)
};
